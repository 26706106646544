/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Deepchecks Monitoring
 * OpenAPI spec version: 0.1.0
 */
import { useQuery, useMutation } from '@tanstack/react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query';
import { customInstance } from '../helpers/services/customAxios';
import type { ErrorType } from '../helpers/services/customAxios';
export type SlackInstallationCallbackApiV1SlackInstallGetParams = { code: string; error?: string; state?: string };

export type GetOnboardingStateApiV1OnboardingGetParams = { model_name?: string };

export type GetOrCreateVersionApiV1ModelsModelIdVersionPostParams = { identifier_kind?: IdentifierKind };

export type CreateModelNotesApiV1ModelsModelIdNotesPostParams = { identifier_kind?: IdentifierKind };

export type RetrieveModelNotesApiV1ModelsModelIdNotesGetParams = { identifier_kind?: IdentifierKind };

export type SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostParams = { identifier_kind?: IdentifierKind };

export type RetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetParams = {
  sort_key?: IngestionErrorsSortKey;
  msg_contains?: string;
  model_version_id?: number;
  sort_order?: SortOrder;
  download?: boolean;
  limit?: number;
  offset?: number;
  start_time_epoch?: number;
  end_time_epoch?: number;
};

export type RetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetParams =
  { sort_key?: IngestionErrorsSortKey; sort_order?: SortOrder; download?: boolean; limit?: number; offset?: number };

export type GetModelColumnsApiV1ModelsModelIdColumnsGet200 = { [key: string]: ColumnMetadata };

export type GetModelColumnsApiV1ModelsModelIdColumnsGetParams = { identifier_kind?: IdentifierKind };

export type RetrieveAvailableModelsApiV1AvailableModelsGetParams = { show_all?: boolean };

export type GetVersionsPerModelApiV1ModelsModelIdVersionsGetParams = { identifier_kind?: IdentifierKind };

export type DeleteModelApiV1ModelsModelIdDeleteParams = { identifier_kind?: IdentifierKind };

export type GetModelApiV1ModelsModelIdGetParams = { identifier_kind?: IdentifierKind };

export type RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet200 = { [key: string]: ModelDailyIngestion[] };

export type RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetParams = {
  time_filter?: number;
  end_time?: string;
  identifier_kind?: IdentifierKind;
};

export type RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet200 = { [key: string]: ModelDailyIngestion[] };

export type RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetParams = {
  time_filter?: number;
  end_time?: string;
};

export type LogLabelsApiV1ModelModelIdLabelsPutBodyItem = { [key: string]: any };

export type LogDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem = { [key: string]: any };

export type GetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetParams = {
  resolved?: boolean;
};

export type CountAlertsApiV1DataIngestionAlertsCountActiveGet200 = { [key: string]: number };

export type GetAllAlertRulesApiV1ConfigAlertRulesGetSortbyItem =
  typeof GetAllAlertRulesApiV1ConfigAlertRulesGetSortbyItem[keyof typeof GetAllAlertRulesApiV1ConfigAlertRulesGetSortbyItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllAlertRulesApiV1ConfigAlertRulesGetSortbyItem = {
  severityasc: 'severity:asc',
  severitydesc: 'severity:desc'
} as const;

export type GetAllAlertRulesApiV1ConfigAlertRulesGetParams = {
  models?: number[];
  severity?: AlertSeverity[];
  sortby?: GetAllAlertRulesApiV1ConfigAlertRulesGetSortbyItem[];
};

export type GetCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost200Item = { [key: string]: any };

export type GetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetParams = { identifier_kind?: IdentifierKind };

export type DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteParams = { identifier_kind?: IdentifierKind };

export type GetChecksApiV1ModelsModelIdChecksGetParams = { identifier_kind?: IdentifierKind };

export type DeleteChecksByNameApiV1ModelsModelIdChecksDeleteParams = {
  names: string[];
  identifier_kind?: IdentifierKind;
};

export type AddChecksApiV1ModelsModelIdChecksPostBody = CheckCreationSchema | CheckCreationSchema[];

export type AddChecksApiV1ModelsModelIdChecksPostParams = { identifier_kind?: IdentifierKind };

export type UpdateWebhookApiV1AlertWebhooksWebhookIdPutBody =
  | PartialStandardWebhookProperties
  | PartialPagerDutyWebhookProperties;

export type CreateWebhookApiV1AlertWebhooksPost201 = { [key: string]: number };

export type CreateWebhookApiV1AlertWebhooksPostBody = StandardWebhookProperties | PagerDutyWebhookProperties;

export type GetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetParams = { resolved?: boolean };

export type GetAlertRulesApiV1AlertRulesGetSortbyItem =
  typeof GetAlertRulesApiV1AlertRulesGetSortbyItem[keyof typeof GetAlertRulesApiV1AlertRulesGetSortbyItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAlertRulesApiV1AlertRulesGetSortbyItem = {
  severityasc: 'severity:asc',
  severitydesc: 'severity:desc',
  'alert-windowasc': 'alert-window:asc',
  'alert-windowdesc': 'alert-window:desc'
} as const;

export type GetAlertRulesApiV1AlertRulesGetParams = {
  monitor_id?: number;
  start?: string;
  end?: string;
  models?: number[];
  severity?: AlertSeverity[];
  is_active?: boolean;
  resolved?: boolean;
  sortby?: GetAlertRulesApiV1AlertRulesGetSortbyItem[];
};

export type GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetSortbyItem =
  typeof GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetSortbyItem[keyof typeof GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetSortbyItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetSortbyItem = {
  severityasc: 'severity:asc',
  severitydesc: 'severity:desc',
  'alert-windowasc': 'alert-window:asc',
  'alert-windowdesc': 'alert-window:desc'
} as const;

export type GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetParams = {
  start?: string;
  end?: string;
  models?: number[];
  severity?: AlertSeverity[];
  is_active?: boolean;
  resolved?: boolean;
  sortby?: GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetSortbyItem[];
};

export type CountAlertsApiV1AlertsCountActiveGet200 = { [key: string]: number };

export interface DeepchecksMonitoringEeApiV1MembersIdNotifySchema {
  id: number;
  notify: boolean;
}

export interface DeepchecksMonitoringApiV1ModelIdNotifySchema {
  id: number;
  notify: boolean;
}

/**
 * Schema for organization.
 */
export interface DeepchecksMonitoringApiV1GlobalApiUsersOrganizationSchema {
  id: number;
  name: string;
  tier: OrgTier;
}

/**
 * Schema for the organization.
 */
export interface DeepchecksMonitoringApiV1GlobalApiOrganizationOrganizationSchema {
  name: string;
  is_slack_connected: boolean;
  is_webhook_connected: boolean;
  slack_notification_levels: AlertSeverity[];
  email_notification_levels: AlertSeverity[];
  webhook_notification_levels: AlertSeverity[];
}

/**
 * Schema for getting rows in a specific window.
 */
export interface WindowDataSchema {
  filter?: DataFilterList;
  end_time: string;
  start_time: string;
  rows_count?: number;
}

/**
 * Kind of request payload that webhook will form.
 */
export type WebhookKind = typeof WebhookKind[keyof typeof WebhookKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookKind = {
  STANDARD: 'STANDARD',
  PAGER_DUTY: 'PAGER_DUTY'
} as const;

/**
 * HTTP method used by webhook.
 */
export type WebhookHttpMethod = typeof WebhookHttpMethod[keyof typeof WebhookHttpMethod];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookHttpMethod = {
  GET: 'GET',
  POST: 'POST'
} as const;

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

/**
 * Schema for user with roles.
 */
export interface UserSchema {
  id: number;
  email: string;
  created_at: string;
  full_name?: string;
  picture_url?: string;
  organization?: DeepchecksMonitoringApiV1GlobalApiUsersOrganizationSchema;
  roles: RoleEnum[];
}

/**
 * Monitor run schema.
 */
export interface TimeWindowSchema {
  end_time?: string;
  start_time?: string;
}

/**
 * Monitor run schema.
 */
export interface TimeWindowOutputStatsSchema {
  num_labeled_samples?: number;
  num_samples?: number;
}

/**
 * Enum containing supported task types.
 */
export type TaskType = unknown;

/**
 * Class for selecting a specific amount of rows on a table data.
 */
export interface TableDataSchema {
  filter?: DataFilterList;
  rows_count?: number;
}

/**
 * Sort order of ingestion errors output.
 */
export type Step = typeof Step[keyof typeof Step];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Step = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4
} as const;

/**
 * Schema for onboarding steps.
 */
export interface StepSchema {
  step: Step;
}

export type StandardWebhookPropertiesHttpHeaders = { [key: string]: string };

export type StandardWebhookPropertiesKind =
  typeof StandardWebhookPropertiesKind[keyof typeof StandardWebhookPropertiesKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StandardWebhookPropertiesKind = {
  STANDARD: 'STANDARD'
} as const;

/**
 * Standard webhook initialization properties.
 */
export interface StandardWebhookProperties {
  kind?: StandardWebhookPropertiesKind;
  name: string;
  description?: string;
  http_url: string;
  http_method: WebhookHttpMethod;
  http_headers?: StandardWebhookPropertiesHttpHeaders;
  notification_levels?: AlertSeverity[];
}

/**
 * Sort order of ingestion errors output.
 */
export type SortOrder = typeof SortOrder[keyof typeof SortOrder];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SortOrder = {
  asc: 'asc',
  desc: 'desc'
} as const;

/**
 * Options for running check on a specific window.
 */
export interface SingleCheckRunOptions {
  filter?: DataFilterList;
  end_time: string;
  start_time: string;
  additional_kwargs?: MonitorCheckConfSchema;
}

/**
 * Roles enum.
 */
export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleEnum = {
  admin: 'admin',
  owner: 'owner'
} as const;

/**
 * Role update schema.
 */
export interface RoleUpdateSchema {
  roles: RoleEnum[];
  replace?: boolean;
}

export type PartialStandardWebhookPropertiesHttpHeaders = { [key: string]: string };

export type PartialStandardWebhookPropertiesKind =
  typeof PartialStandardWebhookPropertiesKind[keyof typeof PartialStandardWebhookPropertiesKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartialStandardWebhookPropertiesKind = {
  STANDARD: 'STANDARD'
} as const;

/**
 * Standard webhook properties.
 */
export interface PartialStandardWebhookProperties {
  kind?: PartialStandardWebhookPropertiesKind;
  name?: string;
  description?: string;
  http_url?: string;
  http_method?: WebhookHttpMethod;
  http_headers?: PartialStandardWebhookPropertiesHttpHeaders;
  notification_levels?: AlertSeverity[];
}

export type PartialPagerDutyWebhookPropertiesKind =
  typeof PartialPagerDutyWebhookPropertiesKind[keyof typeof PartialPagerDutyWebhookPropertiesKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartialPagerDutyWebhookPropertiesKind = {
  PAGER_DUTY: 'PAGER_DUTY'
} as const;

/**
 * PagerDuty service webhook properties.
 */
export interface PartialPagerDutyWebhookProperties {
  kind?: PartialPagerDutyWebhookPropertiesKind;
  name?: string;
  description?: string;
  notification_levels?: AlertSeverity[];
  api_access_key?: string;
  event_routing_key?: string;
  event_group?: string;
  event_class?: string;
}

export type PagerDutyWebhookPropertiesKind =
  typeof PagerDutyWebhookPropertiesKind[keyof typeof PagerDutyWebhookPropertiesKind];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PagerDutyWebhookPropertiesKind = {
  PAGER_DUTY: 'PAGER_DUTY'
} as const;

/**
 * PagerDuty service webhook initialization properties.
 */
export interface PagerDutyWebhookProperties {
  kind?: PagerDutyWebhookPropertiesKind;
  http_url?: string;
  name: string;
  description: string;
  notification_levels?: AlertSeverity[];
  api_access_key?: string;
  event_routing_key: string;
  event_group?: string;
  event_class?: string;
}

/**
 * Schema for the organization update.
 */
export interface OrganizationUpdateSchema {
  slack_notification_levels?: AlertSeverity[];
  email_notification_levels?: AlertSeverity[];
  webhook_notification_levels?: AlertSeverity[];
}

/**
 * Organization tier.
 */
export type OrgTier = typeof OrgTier[keyof typeof OrgTier];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrgTier = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  SCALE: 'SCALE',
  DEDICATED: 'DEDICATED'
} as const;

/**
 * Operators for numeric and categorical filters.
 */
export type OperatorsEnum = typeof OperatorsEnum[keyof typeof OperatorsEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatorsEnum = {
  greater_than_equals: 'greater_than_equals',
  greater_than: 'greater_than',
  less_than_equals: 'less_than_equals',
  less_than: 'less_than',
  equals: 'equals',
  not_equals: 'not_equals',
  in: 'in'
} as const;

/**
 * Schema defines a response containing only id and name.
 */
export interface NameIdResponse {
  id: number;
  name: string;
}

/**
 * List of data filters.
 */
export interface MonitorValueConf {
  name: string;
  is_agg?: boolean;
}

export type MonitorUpdateSchemaAdditionalKwargs = MonitorCheckConfSchema | null;

export type MonitorUpdateSchemaDataFilters = DataFilterList | null;

/**
 * Schema defines the parameters for creating new monitor.
 */
export interface MonitorUpdateSchema {
  name?: string;
  lookback?: number;
  description?: string;
  data_filters?: MonitorUpdateSchemaDataFilters;
  dashboard_id?: number | null;
  additional_kwargs?: MonitorUpdateSchemaAdditionalKwargs;
  frequency?: Frequency;
  aggregation_window?: number;
}

/**
 * List of data filters.
 */
export interface MonitorTypeConf {
  type: string;
  values?: MonitorValueConf[];
  is_agg_shown?: boolean;
}

/**
 * Schema for the monitor.
 */
export interface MonitorSchema {
  id: number;
  name: string;
  check: CheckSchema;
  dashboard_id?: number | null;
  lookback: number;
  aggregation_window: number;
  description?: string;
  data_filters?: DataFilterList;
  additional_kwargs?: MonitorCheckConfSchema;
  alert_rules: AlertRuleSchema[];
  frequency: Frequency;
}

/**
 * Schema defines the parameters for creating new monitor.
 */
export interface MonitorRunSchema {
  end_time?: string;
}

/**
 * Add to single window monitor options frequency and aggregation window to make it multi window.
 */
export interface MonitorOptions {
  filter?: DataFilterList;
  end_time: string;
  start_time: string;
  additional_kwargs?: MonitorCheckConfSchema;
  frequency?: Frequency;
  aggregation_window?: number;
}

/**
 * Schema to get a monitor script/notebook.
 */
export interface MonitorNotebookSchema {
  end_time: string;
  start_time: string;
  model_version_id?: number;
  as_script?: boolean;
}

export type MonitorCreationSchemaAdditionalKwargs = MonitorCheckConfSchema | null;

export type MonitorCreationSchemaDataFilters = DataFilterList | null;

/**
 * Schema defines the parameters for creating new monitor.
 */
export interface MonitorCreationSchema {
  name: string;
  lookback: number;
  aggregation_window: number;
  frequency: Frequency;
  dashboard_id?: number | null;
  description?: string;
  data_filters?: MonitorCreationSchemaDataFilters;
  additional_kwargs?: MonitorCreationSchemaAdditionalKwargs;
}

export type MonitorCheckConfSchemaCheckConf = { [key: string]: string[] };

/**
 * List of data filters.
 */
export interface MonitorCheckConfSchema {
  check_conf: MonitorCheckConfSchemaCheckConf;
  res_conf?: string[];
}

/**
 * List of data filters.
 */
export interface MonitorCheckConf {
  check_conf?: MonitorTypeConf[];
  res_conf?: MonitorTypeConf;
}

export type ModelVersionUpdateSchemaFeatureImportance = { [key: string]: number };

/**
 * ModelVersion update schema.
 */
export interface ModelVersionUpdateSchema {
  name?: string;
  feature_importance?: ModelVersionUpdateSchemaFeatureImportance;
}

export type ModelVersionSchemaLabelMap = { [key: string]: string };

export type ModelVersionSchemaStatistics = { [key: string]: any };

export type ModelVersionSchemaFeatureImportance = { [key: string]: any };

export type ModelVersionSchemaMetaColumns = { [key: string]: any };

export type ModelVersionSchemaModelColumns = { [key: string]: any };

export type ModelVersionSchemaAdditionalDataColumns = { [key: string]: any };

export type ModelVersionSchemaFeaturesColumns = { [key: string]: any };

/**
 * Model version schema.
 */
export interface ModelVersionSchema {
  id: number;
  model_id: number;
  name: string;
  start_time: string;
  end_time: string;
  features_columns: ModelVersionSchemaFeaturesColumns;
  additional_data_columns: ModelVersionSchemaAdditionalDataColumns;
  model_columns: ModelVersionSchemaModelColumns;
  meta_columns: ModelVersionSchemaMetaColumns;
  feature_importance?: ModelVersionSchemaFeatureImportance;
  statistics?: ModelVersionSchemaStatistics;
  classes?: string[];
  label_map?: ModelVersionSchemaLabelMap;
  balance_classes: boolean;
}

/**
 * ModelVersion schema for the "Model managment" screen.
 */
export interface ModelVersionManagmentSchema {
  id: number;
  model_id: number;
  name: string;
  start_time: string;
  end_time: string;
}

export type ModelVersionCreationSchemaLabelMap = { [key: string]: string };

export type ModelVersionCreationSchemaFeatureImportance = { [key: string]: number };

export type ModelVersionCreationSchemaAdditionalData = { [key: string]: ColumnType };

export type ModelVersionCreationSchemaFeatures = { [key: string]: ColumnType };

/**
 * Schema defines the parameters for creating new model version.
 */
export interface ModelVersionCreationSchema {
  name: string;
  features: ModelVersionCreationSchemaFeatures;
  additional_data: ModelVersionCreationSchemaAdditionalData;
  feature_importance?: ModelVersionCreationSchemaFeatureImportance;
  classes?: string[];
  label_map?: ModelVersionCreationSchemaLabelMap;
}

/**
 * Model Schema.
 */
export interface ModelSchema {
  id: number;
  name: string;
  description?: string;
  task_type?: TaskType;
  alerts_delay_labels_ratio: number;
  alerts_delay_seconds: number;
  obj_store_path?: string;
}

/**
 * Model Schedule Time Schema.
 */
export interface ModelScheduleTimeSchema {
  timestamp: string;
}

/**
 * Note schema.
 */
export interface ModelNoteSchema {
  title: string;
  text?: string;
  id: string;
  created_at: string;
  model_id: number;
}

/**
 * Note schema.
 */
export interface ModelNoteCreationSchema {
  title: string;
  text?: string;
}

export type ModelManagmentSchemaSeveritiesCount = { [key: string]: number };

/**
 * Model schema for the "Model managment" screen.
 */
export interface ModelManagmentSchema {
  id: number;
  name: string;
  alerts_count: number;
  monitors_count: number;
  latest_time?: number;
  start_time?: number;
  description?: string;
  task_type?: TaskType;
  has_data?: boolean;
  max_severity?: AlertSeverity;
  versions: ModelVersionManagmentSchema[];
  members: DeepchecksMonitoringApiV1ModelIdNotifySchema[];
  severities_count: ModelManagmentSchemaSeveritiesCount;
}

export interface ModelDailyIngestion {
  count: number;
  label_count: number;
  timestamp: number;
}

/**
 * Model schema.
 */
export interface ModelCreationSchema {
  name: string;
  description?: string;
  task_type: TaskType;
  alerts_delay_labels_ratio: number;
  alerts_delay_seconds: number;
  notes?: ModelNoteCreationSchema[];
  obj_store_path?: string;
}

/**
 * Member update schema.
 */
export interface MemberUpdateSchema {
  models: DeepchecksMonitoringEeApiV1MembersIdNotifySchema[];
  replace?: boolean;
}

/**
 * Schema for a member.
 */
export interface MemberSchema {
  id: number;
  email: string;
  full_name?: string;
  disabled: boolean;
  picture_url?: string;
  last_login?: string;
  created_at: string;
  roles: RoleEnum[];
}

/**
 * Schema for info on invitation.
 */
export interface InvitationInfoSchema {
  from_user: string;
  org_name: string;
}

export type InvitationCreationSchemaEmail = string | string[];

/**
 * Schema for the invitation creation.
 */
export interface InvitationCreationSchema {
  email: InvitationCreationSchemaEmail;
  ttl?: number;
}

/**
 * Sort key of ingestion errors output.
 */
export type IngestionErrorsSortKey = typeof IngestionErrorsSortKey[keyof typeof IngestionErrorsSortKey];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IngestionErrorsSortKey = {
  timestamp: 'timestamp',
  error: 'error'
} as const;

/**
 * IngestionError output schema.
 */
export interface IngestionErrorSchema {
  id: number;
  sample_id?: string;
  error?: string;
  sample?: string;
  created_at: string;
  model_version_id?: number;
}

/**
 * Identifier kind.
 */
export type IdentifierKind = unknown;

/**
 * Schema defines a response containing only id.
 */
export interface IdResponse {
  id: number;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

/**
 * Monitor execution frequency.
 */
export type Frequency = typeof Frequency[keyof typeof Frequency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Frequency = {
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
} as const;

/**
 * Schema to be returned to the client for the features control.
 */
export interface FeaturesSchema {
  max_models: number;
  signup_enabled: boolean;
  slack_enabled: boolean;
  rows_per_minute: number;
  custom_checks_enabled: boolean;
  data_retention_months: number;
  monthly_predictions_limit: number;
  sso_enabled: boolean;
  onboarding_enabled: boolean;
  update_roles: boolean;
  model_assignment: boolean;
  email_enabled: boolean;
}

export type DataSourceSchemaParameters = { [key: string]: any };

/**
 * Data Source schema.
 */
export interface DataSourceSchema {
  type: string;
  parameters: DataSourceSchemaParameters;
  id: number;
}

export type DataSourceCreationSchemaParameters = { [key: string]: any };

/**
 * Data Source creation schema.
 */
export interface DataSourceCreationSchema {
  type: string;
  parameters: DataSourceCreationSchemaParameters;
}

/**
 * Schema for the alert.
 */
export interface DataIngestionAlertSchema {
  id: number;
  created_at: string;
  alert_rule_id: number;
  value: number;
  start_time: string;
  end_time: string;
  resolved: boolean;
}

/**
 * Schema defines the parameters for updating alert rule.
 */
export interface DataIngestionAlertRuleUpdateSchema {
  name?: string;
  alert_severity?: AlertSeverity;
  condition?: Condition;
  is_active?: boolean;
}

/**
 * Schema for the alert rule.
 */
export interface DataIngestionAlertRuleSchema {
  id: number;
  name: string;
  model_id: number;
  condition: Condition;
  alert_severity?: AlertSeverity;
  is_active: boolean;
}

/**
 * Schema defines the parameters for creating new alert rule.
 */
export interface DataIngestionAlertRuleCreationSchema {
  name: string;
  condition: Condition;
  alert_severity?: AlertSeverity;
  is_active?: boolean;
  alert_type: AlertRuleType;
  frequency: Frequency;
}

/**
 * Filter to be used on data, column can be feature/non-feature and value can be numeric/string.
 */
export interface DataFilter {
  column: string;
  operator: OperatorsEnum;
  value?: unknown;
}

/**
 * List of data filters.
 */
export interface DataFilterList {
  filters: DataFilter[];
}

/**
 * Schema defines the parameters for updating a dashboard.
 */
export interface DashboardUpdateSchema {
  name: string;
}

/**
 * Schema for the dashboard.
 */
export interface DashboardSchema {
  id: number;
  name?: string;
  monitors: MonitorSchema[];
}

/**
 * ModelVersion schema for the "Connected Models" screen.
 */
export interface ConnectedModelVersionSchema {
  id: number;
  name: string;
  last_update_time?: string;
  n_of_pending_rows: number;
  n_of_alerts: number;
}

/**
 * Model schema for the "Connected Models" screen.
 */
export interface ConnectedModelSchema {
  id: number;
  name: string;
  description?: string;
  task_type?: TaskType;
  n_of_alerts: number;
  n_of_pending_rows: number;
  n_of_updating_versions: number;
  latest_update?: string;
  sample_count: number;
  label_count: number;
  label_ratio: number;
}

/**
 * Condition to define an alert on check result, value must be numeric.
 */
export interface Condition {
  operator: OperatorsEnum;
  value: number;
}

/**
 * Schema for to update complete details page.
 */
export interface CompleteDetailsUpdateSchema {
  new_organization_name?: string;
  user_full_name?: string;
  accept_invite?: boolean;
}

/**
 * Schema for complete details page.
 */
export interface CompleteDetailsSchema {
  invitation?: InvitationInfoSchema;
  user_full_name?: string;
  organization_name?: string;
}

/**
 * Enum containing possible types of data.
 */
export type ColumnType = typeof ColumnType[keyof typeof ColumnType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ColumnType = {
  numeric: 'numeric',
  integer: 'integer',
  bigint: 'bigint',
  categorical: 'categorical',
  boolean: 'boolean',
  text: 'text',
  array_float: 'array_float',
  array_float_2d: 'array_float_2d',
  datetime: 'datetime'
} as const;

/**
 * A typed object represents a numeric column statistic.
 */
export interface ColumnStatistics {
  max?: number;
  min?: number;
  values?: string[];
}

/**
 * TypedDict containing relevant column metadata.
 */
export interface ColumnMetadata {
  type: ColumnType;
  stats: ColumnStatistics;
}

/**
 * Schema for the check.
 */
export interface CheckSchema {
  config: CheckConfigSchema;
  model_id: number;
  id: number;
  docs_link?: string | null;
  name?: string;
}

/**
 * Basic schema for running a check.
 */
export interface CheckRunOptions {
  filter?: DataFilterList;
  additional_kwargs?: MonitorCheckConfSchema;
}

export type CheckResultSchemaOutput = { [key: string]: any };

/**
 * Check run result schema.
 */
export interface CheckResultSchema {
  output: CheckResultSchemaOutput;
  time_labels: string[];
}

/**
 * Schema to get a check script/notebook.
 */
export interface CheckNotebookSchema {
  filter?: DataFilterList;
  end_time: string;
  start_time: string;
  additional_kwargs?: MonitorCheckConfSchema;
  model_version_id?: number;
  as_script?: boolean;
}

export type CheckGroupBySchemaValue = { [key: string]: any };

/**
 * Schema for result of a check group by run.
 */
export interface CheckGroupBySchema {
  name?: string | null;
  value?: CheckGroupBySchemaValue;
  count: number;
  filters: DataFilterList;
}

export type CheckConfigSchemaParams = { [key: string]: any };

export interface CheckConfigSchema {
  module_name: string;
  class_name: string;
  params: CheckConfigSchemaParams;
}

/**
 * Check schema.
 */
export interface CheckCreationSchema {
  config: CheckConfigSchema;
  name?: string;
}

export interface BodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost {
  batch: Blob;
}

/**
 * Member update schema.
 */
export interface BatchModelMemberUpdateSchema {
  user_ids: number[];
  replace?: boolean;
}

/**
 * Schema for user.
 */
export interface BasicUserSchema {
  id: number;
  email: string;
  created_at: string;
  full_name?: string;
  picture_url?: string;
  organization?: DeepchecksMonitoringApiV1GlobalApiUsersOrganizationSchema;
}

/**
 * Response for auto frequency.
 */
export interface AutoFrequencyResponse {
  frequency: Frequency;
  start: number;
  end: number;
}

export type AlertWebhookSchemaLatestExecutionStatus = { [key: string]: any };

export type AlertWebhookSchemaAdditionalArguments = { [key: string]: any };

export type AlertWebhookSchemaHttpHeaders = { [key: string]: any };

/**
 * Enum for the alert severity.
 */
export type AlertSeverity = typeof AlertSeverity[keyof typeof AlertSeverity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertSeverity = {
  low: 'low',
  medium: 'medium',
  high: 'high',
  critical: 'critical'
} as const;

/**
 * Alert webhook schema.
 */
export interface AlertWebhookSchema {
  id: number;
  name: string;
  description?: string;
  kind: WebhookKind;
  http_url: string;
  http_method: WebhookHttpMethod;
  http_headers: AlertWebhookSchemaHttpHeaders;
  notification_levels: AlertSeverity[];
  additional_arguments: AlertWebhookSchemaAdditionalArguments;
  latest_execution_date?: string;
  latest_execution_status?: AlertWebhookSchemaLatestExecutionStatus;
}

export type AlertSchemaFailedValues = { [key: string]: { [key: string]: number } };

/**
 * Schema for the alert.
 */
export interface AlertSchema {
  alert_rule_id: number;
  failed_values: AlertSchemaFailedValues;
  start_time: string;
  end_time: string;
  resolved: boolean;
  id: number;
  created_at: string;
}

/**
 * Schema defines the parameters for updating alert rule.
 */
export interface AlertRuleUpdateSchema {
  alert_severity?: AlertSeverity;
  condition?: Condition;
  is_active?: boolean;
}

/**
 * An enumeration.
 */
export type AlertRuleType = typeof AlertRuleType[keyof typeof AlertRuleType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertRuleType = {
  sample_count: 'sample_count',
  label_count: 'label_count',
  label_ratio: 'label_ratio'
} as const;

/**
 * Schema for the alert rule.
 */
export interface AlertRuleSchema {
  id: number;
  monitor_id: number;
  condition: Condition;
  alert_severity?: AlertSeverity;
  is_active: boolean;
  start_time?: string;
}

/**
 * Schema of alert rule info for display.
 */
export interface AlertRuleInfoSchema {
  id: number;
  monitor_id: number;
  condition: Condition;
  alert_severity?: AlertSeverity;
  is_active: boolean;
  start_time?: string;
  model_id: number;
  alerts_count?: number;
  max_end_time?: string;
}

/**
 * Schema defines the parameters for creating new alert rule.
 */
export interface AlertRuleCreationSchema {
  condition: Condition;
  alert_severity?: AlertSeverity;
  is_active?: boolean;
}

/**
 * Schema for the alert rule.
 */
export interface AlertRuleConfigSchema {
  id: number;
  name: string;
  frequency: Frequency;
  condition: Condition;
  alert_severity?: AlertSeverity;
  total_alerts?: number;
  non_resolved_alerts?: number;
  recent_alert?: string;
  user?: BasicUserSchema;
  check_name: string;
}

/**
 * @summary Hello World
 */
export const helloWorldApiV1SayHelloGet = (signal?: AbortSignal) => {
  return customInstance<string>({ url: `/api/v1/say-hello`, method: 'get', signal });
};

export const getHelloWorldApiV1SayHelloGetQueryKey = () => [`/api/v1/say-hello`];

export type HelloWorldApiV1SayHelloGetQueryResult = NonNullable<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>>;
export type HelloWorldApiV1SayHelloGetQueryError = ErrorType<unknown>;

export const useHelloWorldApiV1SayHelloGet = <
  TData = Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHelloWorldApiV1SayHelloGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>> = ({ signal }) =>
    helloWorldApiV1SayHelloGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof helloWorldApiV1SayHelloGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Retrieve Backend Version
 */
export const retrieveBackendVersionApiV1BackendVersionGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/backend-version`, method: 'get', signal });
};

export const getRetrieveBackendVersionApiV1BackendVersionGetQueryKey = () => [`/api/v1/backend-version`];

export type RetrieveBackendVersionApiV1BackendVersionGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>
>;
export type RetrieveBackendVersionApiV1BackendVersionGetQueryError = ErrorType<unknown>;

export const useRetrieveBackendVersionApiV1BackendVersionGet = <
  TData = Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveBackendVersionApiV1BackendVersionGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>> = ({
    signal
  }) => retrieveBackendVersionApiV1BackendVersionGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveBackendVersionApiV1BackendVersionGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Count alerts.
 * @summary Count Alerts
 */
export const countAlertsApiV1AlertsCountActiveGet = (signal?: AbortSignal) => {
  return customInstance<CountAlertsApiV1AlertsCountActiveGet200>({
    url: `/api/v1/alerts/count_active`,
    method: 'get',
    signal
  });
};

export const getCountAlertsApiV1AlertsCountActiveGetQueryKey = () => [`/api/v1/alerts/count_active`];

export type CountAlertsApiV1AlertsCountActiveGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof countAlertsApiV1AlertsCountActiveGet>>
>;
export type CountAlertsApiV1AlertsCountActiveGetQueryError = ErrorType<unknown>;

export const useCountAlertsApiV1AlertsCountActiveGet = <
  TData = Awaited<ReturnType<typeof countAlertsApiV1AlertsCountActiveGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countAlertsApiV1AlertsCountActiveGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCountAlertsApiV1AlertsCountActiveGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countAlertsApiV1AlertsCountActiveGet>>> = ({ signal }) =>
    countAlertsApiV1AlertsCountActiveGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof countAlertsApiV1AlertsCountActiveGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Resolve alert by id.
 * @summary Resolve Alert
 */
export const resolveAlertApiV1AlertsAlertIdResolvePost = (alertId: number) => {
  return customInstance<unknown>({ url: `/api/v1/alerts/${alertId}/resolve`, method: 'post' });
};

export type ResolveAlertApiV1AlertsAlertIdResolvePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof resolveAlertApiV1AlertsAlertIdResolvePost>>
>;

export type ResolveAlertApiV1AlertsAlertIdResolvePostMutationError = ErrorType<HTTPValidationError>;

export const useResolveAlertApiV1AlertsAlertIdResolvePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveAlertApiV1AlertsAlertIdResolvePost>>,
    TError,
    { alertId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resolveAlertApiV1AlertsAlertIdResolvePost>>,
    { alertId: number }
  > = props => {
    const { alertId } = props ?? {};

    return resolveAlertApiV1AlertsAlertIdResolvePost(alertId);
  };

  return useMutation<
    Awaited<ReturnType<typeof resolveAlertApiV1AlertsAlertIdResolvePost>>,
    TError,
    { alertId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Reactivate resolved alert.
 * @summary Reactivate Alert
 */
export const reactivateAlertApiV1AlertsAlertIdReactivatePost = (alertId: number) => {
  return customInstance<unknown>({ url: `/api/v1/alerts/${alertId}/reactivate`, method: 'post' });
};

export type ReactivateAlertApiV1AlertsAlertIdReactivatePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof reactivateAlertApiV1AlertsAlertIdReactivatePost>>
>;

export type ReactivateAlertApiV1AlertsAlertIdReactivatePostMutationError = ErrorType<HTTPValidationError>;

export const useReactivateAlertApiV1AlertsAlertIdReactivatePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reactivateAlertApiV1AlertsAlertIdReactivatePost>>,
    TError,
    { alertId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reactivateAlertApiV1AlertsAlertIdReactivatePost>>,
    { alertId: number }
  > = props => {
    const { alertId } = props ?? {};

    return reactivateAlertApiV1AlertsAlertIdReactivatePost(alertId);
  };

  return useMutation<
    Awaited<ReturnType<typeof reactivateAlertApiV1AlertsAlertIdReactivatePost>>,
    TError,
    { alertId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get alert by id.
 * @summary Get Alert
 */
export const getAlertApiV1AlertsAlertIdGet = (alertId: number, signal?: AbortSignal) => {
  return customInstance<AlertSchema>({ url: `/api/v1/alerts/${alertId}`, method: 'get', signal });
};

export const getGetAlertApiV1AlertsAlertIdGetQueryKey = (alertId: number) => [`/api/v1/alerts/${alertId}`];

export type GetAlertApiV1AlertsAlertIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertApiV1AlertsAlertIdGet>>
>;
export type GetAlertApiV1AlertsAlertIdGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAlertApiV1AlertsAlertIdGet = <
  TData = Awaited<ReturnType<typeof getAlertApiV1AlertsAlertIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  alertId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAlertApiV1AlertsAlertIdGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAlertApiV1AlertsAlertIdGetQueryKey(alertId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertApiV1AlertsAlertIdGet>>> = ({ signal }) =>
    getAlertApiV1AlertsAlertIdGet(alertId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAlertApiV1AlertsAlertIdGet>>, TError, TData>(queryKey, queryFn, {
    enabled: !!alertId,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Delete alert by id.
 * @summary Delete Alert
 */
export const deleteAlertApiV1AlertsAlertIdDelete = (alertId: number) => {
  return customInstance<unknown>({ url: `/api/v1/alerts/${alertId}`, method: 'delete' });
};

export type DeleteAlertApiV1AlertsAlertIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAlertApiV1AlertsAlertIdDelete>>
>;

export type DeleteAlertApiV1AlertsAlertIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteAlertApiV1AlertsAlertIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAlertApiV1AlertsAlertIdDelete>>,
    TError,
    { alertId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAlertApiV1AlertsAlertIdDelete>>,
    { alertId: number }
  > = props => {
    const { alertId } = props ?? {};

    return deleteAlertApiV1AlertsAlertIdDelete(alertId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteAlertApiV1AlertsAlertIdDelete>>,
    TError,
    { alertId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Create new alert rule on a given check.
 * @summary Create new alert rule on a given monitor.
 */
export const createAlertRuleApiV1MonitorsMonitorIdAlertRulesPost = (
  monitorId: number,
  alertRuleCreationSchema: AlertRuleCreationSchema
) => {
  return customInstance<IdResponse>({
    url: `/api/v1/monitors/${monitorId}/alert-rules`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: alertRuleCreationSchema
  });
};

export type CreateAlertRuleApiV1MonitorsMonitorIdAlertRulesPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAlertRuleApiV1MonitorsMonitorIdAlertRulesPost>>
>;
export type CreateAlertRuleApiV1MonitorsMonitorIdAlertRulesPostMutationBody = AlertRuleCreationSchema;
export type CreateAlertRuleApiV1MonitorsMonitorIdAlertRulesPostMutationError = ErrorType<HTTPValidationError>;

export const useCreateAlertRuleApiV1MonitorsMonitorIdAlertRulesPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAlertRuleApiV1MonitorsMonitorIdAlertRulesPost>>,
    TError,
    { monitorId: number; data: AlertRuleCreationSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAlertRuleApiV1MonitorsMonitorIdAlertRulesPost>>,
    { monitorId: number; data: AlertRuleCreationSchema }
  > = props => {
    const { monitorId, data } = props ?? {};

    return createAlertRuleApiV1MonitorsMonitorIdAlertRulesPost(monitorId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createAlertRuleApiV1MonitorsMonitorIdAlertRulesPost>>,
    TError,
    { monitorId: number; data: AlertRuleCreationSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Return all the alert rules.

Parameters
----------
start
end
models
severity
sortby
monitor_id : int
    ID of a monitor to filter alert rules by.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
List[AlertSchema]
    All the alerts for a given monitor.
 * @summary Get Alert Rules
 */
export const getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet = (
  monitorId: number,
  params?: GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetParams,
  signal?: AbortSignal
) => {
  return customInstance<AlertRuleInfoSchema[]>({
    url: `/api/v1/monitors/${monitorId}/alert-rules`,
    method: 'get',
    params,
    signal
  });
};

export const getGetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetQueryKey = (
  monitorId: number,
  params?: GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetParams
) => [`/api/v1/monitors/${monitorId}/alert-rules`, ...(params ? [params] : [])];

export type GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet>>
>;
export type GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAlertRulesApiV1MonitorsMonitorIdAlertRulesGet = <
  TData = Awaited<ReturnType<typeof getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  monitorId: number,
  params?: GetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAlertRulesApiV1MonitorsMonitorIdAlertRulesGetQueryKey(monitorId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet>>> = ({
    signal
  }) => getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet(monitorId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAlertRulesApiV1MonitorsMonitorIdAlertRulesGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!monitorId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Return all the alert rules.

Parameters
----------
start
end
models
severity
sortby
monitor_id : int
    ID of a monitor to filter alert rules by.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
List[AlertSchema]
    All the alerts for a given monitor.
 * @summary Get Alert Rules
 */
export const getAlertRulesApiV1AlertRulesGet = (
  params?: GetAlertRulesApiV1AlertRulesGetParams,
  signal?: AbortSignal
) => {
  return customInstance<AlertRuleInfoSchema[]>({ url: `/api/v1/alert-rules`, method: 'get', params, signal });
};

export const getGetAlertRulesApiV1AlertRulesGetQueryKey = (params?: GetAlertRulesApiV1AlertRulesGetParams) => [
  `/api/v1/alert-rules`,
  ...(params ? [params] : [])
];

export type GetAlertRulesApiV1AlertRulesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertRulesApiV1AlertRulesGet>>
>;
export type GetAlertRulesApiV1AlertRulesGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAlertRulesApiV1AlertRulesGet = <
  TData = Awaited<ReturnType<typeof getAlertRulesApiV1AlertRulesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetAlertRulesApiV1AlertRulesGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAlertRulesApiV1AlertRulesGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAlertRulesApiV1AlertRulesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertRulesApiV1AlertRulesGet>>> = ({ signal }) =>
    getAlertRulesApiV1AlertRulesGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAlertRulesApiV1AlertRulesGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get alert by id.
 * @summary Get Alert Rule
 */
export const getAlertRuleApiV1AlertRulesAlertRuleIdGet = (alertRuleId: number, signal?: AbortSignal) => {
  return customInstance<AlertRuleSchema>({ url: `/api/v1/alert-rules/${alertRuleId}`, method: 'get', signal });
};

export const getGetAlertRuleApiV1AlertRulesAlertRuleIdGetQueryKey = (alertRuleId: number) => [
  `/api/v1/alert-rules/${alertRuleId}`
];

export type GetAlertRuleApiV1AlertRulesAlertRuleIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertRuleApiV1AlertRulesAlertRuleIdGet>>
>;
export type GetAlertRuleApiV1AlertRulesAlertRuleIdGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAlertRuleApiV1AlertRulesAlertRuleIdGet = <
  TData = Awaited<ReturnType<typeof getAlertRuleApiV1AlertRulesAlertRuleIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  alertRuleId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAlertRuleApiV1AlertRulesAlertRuleIdGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAlertRuleApiV1AlertRulesAlertRuleIdGetQueryKey(alertRuleId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertRuleApiV1AlertRulesAlertRuleIdGet>>> = ({ signal }) =>
    getAlertRuleApiV1AlertRulesAlertRuleIdGet(alertRuleId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAlertRuleApiV1AlertRulesAlertRuleIdGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!alertRuleId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update alert by id.
 * @summary Update alert rule by id.
 */
export const updateAlertApiV1AlertRulesAlertRuleIdPut = (
  alertRuleId: number,
  alertRuleUpdateSchema: AlertRuleUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/alert-rules/${alertRuleId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: alertRuleUpdateSchema
  });
};

export type UpdateAlertApiV1AlertRulesAlertRuleIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAlertApiV1AlertRulesAlertRuleIdPut>>
>;
export type UpdateAlertApiV1AlertRulesAlertRuleIdPutMutationBody = AlertRuleUpdateSchema;
export type UpdateAlertApiV1AlertRulesAlertRuleIdPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateAlertApiV1AlertRulesAlertRuleIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlertApiV1AlertRulesAlertRuleIdPut>>,
    TError,
    { alertRuleId: number; data: AlertRuleUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAlertApiV1AlertRulesAlertRuleIdPut>>,
    { alertRuleId: number; data: AlertRuleUpdateSchema }
  > = props => {
    const { alertRuleId, data } = props ?? {};

    return updateAlertApiV1AlertRulesAlertRuleIdPut(alertRuleId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateAlertApiV1AlertRulesAlertRuleIdPut>>,
    TError,
    { alertRuleId: number; data: AlertRuleUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete alert by id.
 * @summary Delete Alert Rule
 */
export const deleteAlertRuleApiV1AlertRulesAlertRuleIdDelete = (alertRuleId: number) => {
  return customInstance<unknown>({ url: `/api/v1/alert-rules/${alertRuleId}`, method: 'delete' });
};

export type DeleteAlertRuleApiV1AlertRulesAlertRuleIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAlertRuleApiV1AlertRulesAlertRuleIdDelete>>
>;

export type DeleteAlertRuleApiV1AlertRulesAlertRuleIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteAlertRuleApiV1AlertRulesAlertRuleIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAlertRuleApiV1AlertRulesAlertRuleIdDelete>>,
    TError,
    { alertRuleId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAlertRuleApiV1AlertRulesAlertRuleIdDelete>>,
    { alertRuleId: number }
  > = props => {
    const { alertRuleId } = props ?? {};

    return deleteAlertRuleApiV1AlertRulesAlertRuleIdDelete(alertRuleId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteAlertRuleApiV1AlertRulesAlertRuleIdDelete>>,
    TError,
    { alertRuleId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get list of alerts raised by a given alert rule.
 * @summary Get Alerts Of Alert Rule
 */
export const getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet = (
  alertRuleId: number,
  params?: GetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<AlertSchema[]>({
    url: `/api/v1/alert-rules/${alertRuleId}/alerts`,
    method: 'get',
    params,
    signal
  });
};

export const getGetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetQueryKey = (
  alertRuleId: number,
  params?: GetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetParams
) => [`/api/v1/alert-rules/${alertRuleId}/alerts`, ...(params ? [params] : [])];

export type GetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet>>
>;
export type GetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet = <
  TData = Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  alertRuleId: number,
  params?: GetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGetQueryKey(alertRuleId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet>>> = ({
    signal
  }) => getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet(alertRuleId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdAlertsGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!alertRuleId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Resolve all alerts of alert rule.
 * @summary Resolve All Alerts Of Alert Rule
 */
export const resolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost = (alertRuleId: number) => {
  return customInstance<unknown>({ url: `/api/v1/alert-rules/${alertRuleId}/resolve-all`, method: 'post' });
};

export type ResolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost>>
>;

export type ResolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPostMutationError =
  ErrorType<HTTPValidationError>;

export const useResolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost>>,
    TError,
    { alertRuleId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost>>,
    { alertRuleId: number }
  > = props => {
    const { alertRuleId } = props ?? {};

    return resolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost(alertRuleId);
  };

  return useMutation<
    Awaited<ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1AlertRulesAlertRuleIdResolveAllPost>>,
    TError,
    { alertRuleId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Reactivate all resolved alerts
 * @summary Reactivate Resolved Alerts
 */
export const reactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost = (alertRuleId: number) => {
  return customInstance<unknown>({
    url: `/api/v1/alert-rules/${alertRuleId}/alerts/reactivate-resolved`,
    method: 'post'
  });
};

export type ReactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof reactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost>>
>;

export type ReactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPostMutationError =
  ErrorType<HTTPValidationError>;

export const useReactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost>>,
    TError,
    { alertRuleId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost>>,
    { alertRuleId: number }
  > = props => {
    const { alertRuleId } = props ?? {};

    return reactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost(alertRuleId);
  };

  return useMutation<
    Awaited<ReturnType<typeof reactivateResolvedAlertsApiV1AlertRulesAlertRuleIdAlertsReactivateResolvedPost>>,
    TError,
    { alertRuleId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrieve all available alert webhooks
 * @summary List Webhooks
 */
export const listWebhooksApiV1AlertWebhooksGet = (signal?: AbortSignal) => {
  return customInstance<AlertWebhookSchema[]>({ url: `/api/v1/alert-webhooks`, method: 'get', signal });
};

export const getListWebhooksApiV1AlertWebhooksGetQueryKey = () => [`/api/v1/alert-webhooks`];

export type ListWebhooksApiV1AlertWebhooksGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof listWebhooksApiV1AlertWebhooksGet>>
>;
export type ListWebhooksApiV1AlertWebhooksGetQueryError = ErrorType<unknown>;

export const useListWebhooksApiV1AlertWebhooksGet = <
  TData = Awaited<ReturnType<typeof listWebhooksApiV1AlertWebhooksGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listWebhooksApiV1AlertWebhooksGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListWebhooksApiV1AlertWebhooksGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listWebhooksApiV1AlertWebhooksGet>>> = ({ signal }) =>
    listWebhooksApiV1AlertWebhooksGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof listWebhooksApiV1AlertWebhooksGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create alert webhook
 * @summary Create Webhook
 */
export const createWebhookApiV1AlertWebhooksPost = (
  createWebhookApiV1AlertWebhooksPostBody: CreateWebhookApiV1AlertWebhooksPostBody
) => {
  return customInstance<CreateWebhookApiV1AlertWebhooksPost201>({
    url: `/api/v1/alert-webhooks`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createWebhookApiV1AlertWebhooksPostBody
  });
};

export type CreateWebhookApiV1AlertWebhooksPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createWebhookApiV1AlertWebhooksPost>>
>;
export type CreateWebhookApiV1AlertWebhooksPostMutationBody = CreateWebhookApiV1AlertWebhooksPostBody;
export type CreateWebhookApiV1AlertWebhooksPostMutationError = ErrorType<HTTPValidationError>;

export const useCreateWebhookApiV1AlertWebhooksPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createWebhookApiV1AlertWebhooksPost>>,
    TError,
    { data: CreateWebhookApiV1AlertWebhooksPostBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createWebhookApiV1AlertWebhooksPost>>,
    { data: CreateWebhookApiV1AlertWebhooksPostBody }
  > = props => {
    const { data } = props ?? {};

    return createWebhookApiV1AlertWebhooksPost(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createWebhookApiV1AlertWebhooksPost>>,
    TError,
    { data: CreateWebhookApiV1AlertWebhooksPostBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrieve alert webhook
 * @summary Retrive Webhook
 */
export const retriveWebhookApiV1AlertWebhooksWebhookIdGet = (webhookId: number, signal?: AbortSignal) => {
  return customInstance<AlertWebhookSchema>({ url: `/api/v1/alert-webhooks/${webhookId}`, method: 'get', signal });
};

export const getRetriveWebhookApiV1AlertWebhooksWebhookIdGetQueryKey = (webhookId: number) => [
  `/api/v1/alert-webhooks/${webhookId}`
];

export type RetriveWebhookApiV1AlertWebhooksWebhookIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retriveWebhookApiV1AlertWebhooksWebhookIdGet>>
>;
export type RetriveWebhookApiV1AlertWebhooksWebhookIdGetQueryError = ErrorType<HTTPValidationError>;

export const useRetriveWebhookApiV1AlertWebhooksWebhookIdGet = <
  TData = Awaited<ReturnType<typeof retriveWebhookApiV1AlertWebhooksWebhookIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  webhookId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof retriveWebhookApiV1AlertWebhooksWebhookIdGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetriveWebhookApiV1AlertWebhooksWebhookIdGetQueryKey(webhookId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retriveWebhookApiV1AlertWebhooksWebhookIdGet>>> = ({
    signal
  }) => retriveWebhookApiV1AlertWebhooksWebhookIdGet(webhookId, signal);

  const query = useQuery<Awaited<ReturnType<typeof retriveWebhookApiV1AlertWebhooksWebhookIdGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!webhookId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update webhook
 * @summary Update Webhook
 */
export const updateWebhookApiV1AlertWebhooksWebhookIdPut = (
  webhookId: number,
  updateWebhookApiV1AlertWebhooksWebhookIdPutBody: UpdateWebhookApiV1AlertWebhooksWebhookIdPutBody
) => {
  return customInstance<unknown>({
    url: `/api/v1/alert-webhooks/${webhookId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateWebhookApiV1AlertWebhooksWebhookIdPutBody
  });
};

export type UpdateWebhookApiV1AlertWebhooksWebhookIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateWebhookApiV1AlertWebhooksWebhookIdPut>>
>;
export type UpdateWebhookApiV1AlertWebhooksWebhookIdPutMutationBody = UpdateWebhookApiV1AlertWebhooksWebhookIdPutBody;
export type UpdateWebhookApiV1AlertWebhooksWebhookIdPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateWebhookApiV1AlertWebhooksWebhookIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateWebhookApiV1AlertWebhooksWebhookIdPut>>,
    TError,
    { webhookId: number; data: UpdateWebhookApiV1AlertWebhooksWebhookIdPutBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateWebhookApiV1AlertWebhooksWebhookIdPut>>,
    { webhookId: number; data: UpdateWebhookApiV1AlertWebhooksWebhookIdPutBody }
  > = props => {
    const { webhookId, data } = props ?? {};

    return updateWebhookApiV1AlertWebhooksWebhookIdPut(webhookId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateWebhookApiV1AlertWebhooksWebhookIdPut>>,
    TError,
    { webhookId: number; data: UpdateWebhookApiV1AlertWebhooksWebhookIdPutBody },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete alert webhook
 * @summary Delete Webhook
 */
export const deleteWebhookApiV1AlertWebhooksWebhookIdDelete = (webhookId: number) => {
  return customInstance<unknown>({ url: `/api/v1/alert-webhooks/${webhookId}`, method: 'delete' });
};

export type DeleteWebhookApiV1AlertWebhooksWebhookIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteWebhookApiV1AlertWebhooksWebhookIdDelete>>
>;

export type DeleteWebhookApiV1AlertWebhooksWebhookIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteWebhookApiV1AlertWebhooksWebhookIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteWebhookApiV1AlertWebhooksWebhookIdDelete>>,
    TError,
    { webhookId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteWebhookApiV1AlertWebhooksWebhookIdDelete>>,
    { webhookId: number }
  > = props => {
    const { webhookId } = props ?? {};

    return deleteWebhookApiV1AlertWebhooksWebhookIdDelete(webhookId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteWebhookApiV1AlertWebhooksWebhookIdDelete>>,
    TError,
    { webhookId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Add a new check or checks to the model.

Parameters
----------
model_identifier : ModelIdentifier
    ID or name of the model.
checks: t.Union[CheckCreationSchema, t.List[CheckCreationSchema]]
    Check or checks to add to model.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
t.List[t.Dict[t.Any, t.Any]]
    List containing the names and ids for uploaded checks.
 * @summary Add Checks
 */
export const addChecksApiV1ModelsModelIdChecksPost = (
  modelId: string | number,
  addChecksApiV1ModelsModelIdChecksPostBody: AddChecksApiV1ModelsModelIdChecksPostBody,
  params?: AddChecksApiV1ModelsModelIdChecksPostParams
) => {
  return customInstance<NameIdResponse[]>({
    url: `/api/v1/models/${modelId}/checks`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: addChecksApiV1ModelsModelIdChecksPostBody,
    params
  });
};

export type AddChecksApiV1ModelsModelIdChecksPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof addChecksApiV1ModelsModelIdChecksPost>>
>;
export type AddChecksApiV1ModelsModelIdChecksPostMutationBody = AddChecksApiV1ModelsModelIdChecksPostBody;
export type AddChecksApiV1ModelsModelIdChecksPostMutationError = ErrorType<HTTPValidationError>;

export const useAddChecksApiV1ModelsModelIdChecksPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addChecksApiV1ModelsModelIdChecksPost>>,
    TError,
    {
      modelId: string | number;
      data: AddChecksApiV1ModelsModelIdChecksPostBody;
      params?: AddChecksApiV1ModelsModelIdChecksPostParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addChecksApiV1ModelsModelIdChecksPost>>,
    {
      modelId: string | number;
      data: AddChecksApiV1ModelsModelIdChecksPostBody;
      params?: AddChecksApiV1ModelsModelIdChecksPostParams;
    }
  > = props => {
    const { modelId, data, params } = props ?? {};

    return addChecksApiV1ModelsModelIdChecksPost(modelId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof addChecksApiV1ModelsModelIdChecksPost>>,
    TError,
    {
      modelId: string | number;
      data: AddChecksApiV1ModelsModelIdChecksPostBody;
      params?: AddChecksApiV1ModelsModelIdChecksPostParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete check instances by name if they exist, otherwise returns 404.
 * @summary Delete Checks By Name
 */
export const deleteChecksByNameApiV1ModelsModelIdChecksDelete = (
  modelId: string | number,
  params: DeleteChecksByNameApiV1ModelsModelIdChecksDeleteParams
) => {
  return customInstance<unknown>({ url: `/api/v1/models/${modelId}/checks`, method: 'delete', params });
};

export type DeleteChecksByNameApiV1ModelsModelIdChecksDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteChecksByNameApiV1ModelsModelIdChecksDelete>>
>;

export type DeleteChecksByNameApiV1ModelsModelIdChecksDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteChecksByNameApiV1ModelsModelIdChecksDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteChecksByNameApiV1ModelsModelIdChecksDelete>>,
    TError,
    { modelId: string | number; params: DeleteChecksByNameApiV1ModelsModelIdChecksDeleteParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteChecksByNameApiV1ModelsModelIdChecksDelete>>,
    { modelId: string | number; params: DeleteChecksByNameApiV1ModelsModelIdChecksDeleteParams }
  > = props => {
    const { modelId, params } = props ?? {};

    return deleteChecksByNameApiV1ModelsModelIdChecksDelete(modelId, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteChecksByNameApiV1ModelsModelIdChecksDelete>>,
    TError,
    { modelId: string | number; params: DeleteChecksByNameApiV1ModelsModelIdChecksDeleteParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Return all the checks for a given model.

Parameters
----------
model_identifier : ModelIdentifier
    ID or name of the model.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
List[CheckSchema]
    All the checks for a given model.
 * @summary Get Checks
 */
export const getChecksApiV1ModelsModelIdChecksGet = (
  modelId: string | number,
  params?: GetChecksApiV1ModelsModelIdChecksGetParams,
  signal?: AbortSignal
) => {
  return customInstance<CheckSchema[]>({ url: `/api/v1/models/${modelId}/checks`, method: 'get', params, signal });
};

export const getGetChecksApiV1ModelsModelIdChecksGetQueryKey = (
  modelId: string | number,
  params?: GetChecksApiV1ModelsModelIdChecksGetParams
) => [`/api/v1/models/${modelId}/checks`, ...(params ? [params] : [])];

export type GetChecksApiV1ModelsModelIdChecksGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getChecksApiV1ModelsModelIdChecksGet>>
>;
export type GetChecksApiV1ModelsModelIdChecksGetQueryError = ErrorType<HTTPValidationError>;

export const useGetChecksApiV1ModelsModelIdChecksGet = <
  TData = Awaited<ReturnType<typeof getChecksApiV1ModelsModelIdChecksGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: GetChecksApiV1ModelsModelIdChecksGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getChecksApiV1ModelsModelIdChecksGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetChecksApiV1ModelsModelIdChecksGetQueryKey(modelId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChecksApiV1ModelsModelIdChecksGet>>> = ({ signal }) =>
    getChecksApiV1ModelsModelIdChecksGet(modelId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getChecksApiV1ModelsModelIdChecksGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!modelId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Delete check instance by identifier.
 * @summary Delete Check By Id
 */
export const deleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete = (
  modelId: string | number,
  checkId: string | number,
  params?: DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteParams
) => {
  return customInstance<unknown>({ url: `/api/v1/models/${modelId}/checks/${checkId}`, method: 'delete', params });
};

export type DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete>>
>;

export type DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete>>,
    TError,
    {
      modelId: string | number;
      checkId: string | number;
      params?: DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete>>,
    {
      modelId: string | number;
      checkId: string | number;
      params?: DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteParams;
    }
  > = props => {
    const { modelId, checkId, params } = props ?? {};

    return deleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete(modelId, checkId, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteCheckByIdApiV1ModelsModelIdChecksCheckIdDelete>>,
    TError,
    {
      modelId: string | number;
      checkId: string | number;
      params?: DeleteCheckByIdApiV1ModelsModelIdChecksCheckIdDeleteParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Infer from the data the best frequency to show for analysis screen.
 * @summary Get Model Auto Frequency
 */
export const getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet = (
  modelId: string | number,
  params?: GetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetParams,
  signal?: AbortSignal
) => {
  return customInstance<AutoFrequencyResponse>({
    url: `/api/v1/models/${modelId}/auto-frequency`,
    method: 'get',
    params,
    signal
  });
};

export const getGetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetQueryKey = (
  modelId: string | number,
  params?: GetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetParams
) => [`/api/v1/models/${modelId}/auto-frequency`, ...(params ? [params] : [])];

export type GetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet>>
>;
export type GetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetQueryError = ErrorType<HTTPValidationError>;

export const useGetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet = <
  TData = Awaited<ReturnType<typeof getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: GetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGetQueryKey(modelId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet>>> = ({
    signal
  }) => getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet(modelId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getModelAutoFrequencyApiV1ModelsModelIdAutoFrequencyGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Run a check for each time window by start-end.

Parameters
----------
check_id : int
    ID of the check.
monitor_options : MonitorOptions
    The "monitor" options.
session : AsyncSession, optional
    SQLAlchemy session.
resources_provider: ResourcesProvider
    Resources provider.

Returns
-------
CheckResultSchema
    Check run result.
 * @summary Run Standalone Check Per Window In Range
 */
export const runStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost = (
  checkId: number,
  monitorOptions: MonitorOptions
) => {
  return customInstance<CheckResultSchema>({
    url: `/api/v1/checks/${checkId}/run/lookback`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: monitorOptions
  });
};

export type RunStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof runStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost>>
>;
export type RunStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPostMutationBody = MonitorOptions;
export type RunStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPostMutationError =
  ErrorType<HTTPValidationError>;

export const useRunStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost>>,
    TError,
    { checkId: number; data: MonitorOptions },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost>>,
    { checkId: number; data: MonitorOptions }
  > = props => {
    const { checkId, data } = props ?? {};

    return runStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost(checkId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof runStandaloneCheckPerWindowInRangeApiV1ChecksCheckIdRunLookbackPost>>,
    TError,
    { checkId: number; data: MonitorOptions },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Run a check for the time window.

Parameters
----------
check_id : int
    ID of the check.
monitor_options : MonitorOptions
    The window options.
session : AsyncSession, optional
    SQLAlchemy session.
resources_provider: ResourcesProvider
    Resources provider.

Returns
-------
dict
    {<version_name: check_res>}.
 * @summary Get Check Window
 */
export const getCheckWindowApiV1ChecksCheckIdRunWindowPost = (
  checkId: number,
  singleCheckRunOptions: SingleCheckRunOptions
) => {
  return customInstance<unknown>({
    url: `/api/v1/checks/${checkId}/run/window`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: singleCheckRunOptions
  });
};

export type GetCheckWindowApiV1ChecksCheckIdRunWindowPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCheckWindowApiV1ChecksCheckIdRunWindowPost>>
>;
export type GetCheckWindowApiV1ChecksCheckIdRunWindowPostMutationBody = SingleCheckRunOptions;
export type GetCheckWindowApiV1ChecksCheckIdRunWindowPostMutationError = ErrorType<HTTPValidationError>;

export const useGetCheckWindowApiV1ChecksCheckIdRunWindowPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCheckWindowApiV1ChecksCheckIdRunWindowPost>>,
    TError,
    { checkId: number; data: SingleCheckRunOptions },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCheckWindowApiV1ChecksCheckIdRunWindowPost>>,
    { checkId: number; data: SingleCheckRunOptions }
  > = props => {
    const { checkId, data } = props ?? {};

    return getCheckWindowApiV1ChecksCheckIdRunWindowPost(checkId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getCheckWindowApiV1ChecksCheckIdRunWindowPost>>,
    TError,
    { checkId: number; data: SingleCheckRunOptions },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Run a check on the reference data.

Parameters
----------
check_id : int
    ID of the check.
monitor_options : CheckRunOptions
    The monitor options.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
dict
    {<version_name: check_res>}.
 * @summary Get Check Reference
 */
export const getCheckReferenceApiV1ChecksCheckIdRunReferencePost = (
  checkId: number,
  checkRunOptions: CheckRunOptions
) => {
  return customInstance<unknown>({
    url: `/api/v1/checks/${checkId}/run/reference`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: checkRunOptions
  });
};

export type GetCheckReferenceApiV1ChecksCheckIdRunReferencePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCheckReferenceApiV1ChecksCheckIdRunReferencePost>>
>;
export type GetCheckReferenceApiV1ChecksCheckIdRunReferencePostMutationBody = CheckRunOptions;
export type GetCheckReferenceApiV1ChecksCheckIdRunReferencePostMutationError = ErrorType<HTTPValidationError>;

export const useGetCheckReferenceApiV1ChecksCheckIdRunReferencePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCheckReferenceApiV1ChecksCheckIdRunReferencePost>>,
    TError,
    { checkId: number; data: CheckRunOptions },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCheckReferenceApiV1ChecksCheckIdRunReferencePost>>,
    { checkId: number; data: CheckRunOptions }
  > = props => {
    const { checkId, data } = props ?? {};

    return getCheckReferenceApiV1ChecksCheckIdRunReferencePost(checkId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getCheckReferenceApiV1ChecksCheckIdRunReferencePost>>,
    TError,
    { checkId: number; data: CheckRunOptions },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Run a check on a specified model version and returns a Jupyter notebook with the code to run the check.

Parameters
----------
check_id : int
    The id of the check to create a notebook to.
notebook_options : CheckNotebookSchema
    The options for the check notebook.
session : AsyncSession, default: AsyncSessionDep
    The database session to use.
settings : Settings, default: SettingsDep

Returns
-------
PlainTextResponse
    A response containing the Jupyter notebook.
 * @summary Get Notebook
 */
export const getNotebookApiV1ChecksCheckIdGetNotebookPost = (
  checkId: number,
  checkNotebookSchema: CheckNotebookSchema
) => {
  return customInstance<string>({
    url: `/api/v1/checks/${checkId}/get-notebook`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: checkNotebookSchema
  });
};

export type GetNotebookApiV1ChecksCheckIdGetNotebookPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getNotebookApiV1ChecksCheckIdGetNotebookPost>>
>;
export type GetNotebookApiV1ChecksCheckIdGetNotebookPostMutationBody = CheckNotebookSchema;
export type GetNotebookApiV1ChecksCheckIdGetNotebookPostMutationError = ErrorType<HTTPValidationError>;

export const useGetNotebookApiV1ChecksCheckIdGetNotebookPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getNotebookApiV1ChecksCheckIdGetNotebookPost>>,
    TError,
    { checkId: number; data: CheckNotebookSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getNotebookApiV1ChecksCheckIdGetNotebookPost>>,
    { checkId: number; data: CheckNotebookSchema }
  > = props => {
    const { checkId, data } = props ?? {};

    return getNotebookApiV1ChecksCheckIdGetNotebookPost(checkId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getNotebookApiV1ChecksCheckIdGetNotebookPost>>,
    TError,
    { checkId: number; data: CheckNotebookSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get the check configuration info and the possible values for the parameters.

Parameters
----------
check_id : int
    ID of the check.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
MonitorCheckConf
    the check configuration info and the possible values for the parameters.
 * @summary Get Check Info
 */
export const getCheckInfoApiV1ChecksCheckIdInfoGet = (checkId: number, signal?: AbortSignal) => {
  return customInstance<MonitorCheckConf>({ url: `/api/v1/checks/${checkId}/info`, method: 'get', signal });
};

export const getGetCheckInfoApiV1ChecksCheckIdInfoGetQueryKey = (checkId: number) => [`/api/v1/checks/${checkId}/info`];

export type GetCheckInfoApiV1ChecksCheckIdInfoGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCheckInfoApiV1ChecksCheckIdInfoGet>>
>;
export type GetCheckInfoApiV1ChecksCheckIdInfoGetQueryError = ErrorType<HTTPValidationError>;

export const useGetCheckInfoApiV1ChecksCheckIdInfoGet = <
  TData = Awaited<ReturnType<typeof getCheckInfoApiV1ChecksCheckIdInfoGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  checkId: number,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getCheckInfoApiV1ChecksCheckIdInfoGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCheckInfoApiV1ChecksCheckIdInfoGetQueryKey(checkId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCheckInfoApiV1ChecksCheckIdInfoGet>>> = ({ signal }) =>
    getCheckInfoApiV1ChecksCheckIdInfoGet(checkId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCheckInfoApiV1ChecksCheckIdInfoGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!checkId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Run check window with a group by on given feature.

Parameters
----------
check_id : int
    ID of the check.
model_version_id : int
feature : str
    Feature to group by
monitor_options : SingleCheckRunOptions
   The monitor options.
session : AsyncSession
    SQLAlchemy session.
resources_provider: ResourcesProvider
    Resources provider.

Returns
-------
List[CheckGroupBySchema]
 * @summary Run Check Group By Feature
 */
export const runCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost = (
  checkId: number,
  modelVersionId: number,
  feature: string,
  singleCheckRunOptions: SingleCheckRunOptions
) => {
  return customInstance<CheckGroupBySchema[]>({
    url: `/api/v1/checks/${checkId}/group-by/${modelVersionId}/${feature}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: singleCheckRunOptions
  });
};

export type RunCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof runCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost>>
>;
export type RunCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePostMutationBody =
  SingleCheckRunOptions;
export type RunCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePostMutationError =
  ErrorType<HTTPValidationError>;

export const useRunCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost>>,
    TError,
    { checkId: number; modelVersionId: number; feature: string; data: SingleCheckRunOptions },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost>>,
    { checkId: number; modelVersionId: number; feature: string; data: SingleCheckRunOptions }
  > = props => {
    const { checkId, modelVersionId, feature, data } = props ?? {};

    return runCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost(
      checkId,
      modelVersionId,
      feature,
      data
    );
  };

  return useMutation<
    Awaited<ReturnType<typeof runCheckGroupByFeatureApiV1ChecksCheckIdGroupByModelVersionIdFeaturePost>>,
    TError,
    { checkId: number; modelVersionId: number; feature: string; data: SingleCheckRunOptions },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Get Check Display
 */
export const getCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost = (
  checkId: number,
  modelVersionId: number,
  singleCheckRunOptions: SingleCheckRunOptions
) => {
  return customInstance<GetCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost200Item[]>({
    url: `/api/v1/checks/${checkId}/display/${modelVersionId}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: singleCheckRunOptions
  });
};

export type GetCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost>>
>;
export type GetCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPostMutationBody = SingleCheckRunOptions;
export type GetCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPostMutationError = ErrorType<HTTPValidationError>;

export const useGetCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost>>,
    TError,
    { checkId: number; modelVersionId: number; data: SingleCheckRunOptions },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost>>,
    { checkId: number; modelVersionId: number; data: SingleCheckRunOptions }
  > = props => {
    const { checkId, modelVersionId, data } = props ?? {};

    return getCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost(checkId, modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getCheckDisplayApiV1ChecksCheckIdDisplayModelVersionIdPost>>,
    TError,
    { checkId: number; modelVersionId: number; data: SingleCheckRunOptions },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Return all alert rules for the configuration screen.

Parameters
----------
models : list, optional
    The list of models to filter by.
severity : list, optional
    The list of severities to filter by.
sortby : list, optional
    The list of columns to sort by.
session : AsyncSession, optional
    The database connection.

Returns
-------
list
    The list of alert rules.
 * @summary Get All Alert Rules
 */
export const getAllAlertRulesApiV1ConfigAlertRulesGet = (
  params?: GetAllAlertRulesApiV1ConfigAlertRulesGetParams,
  signal?: AbortSignal
) => {
  return customInstance<AlertRuleConfigSchema[]>({ url: `/api/v1/config/alert-rules`, method: 'get', params, signal });
};

export const getGetAllAlertRulesApiV1ConfigAlertRulesGetQueryKey = (
  params?: GetAllAlertRulesApiV1ConfigAlertRulesGetParams
) => [`/api/v1/config/alert-rules`, ...(params ? [params] : [])];

export type GetAllAlertRulesApiV1ConfigAlertRulesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllAlertRulesApiV1ConfigAlertRulesGet>>
>;
export type GetAllAlertRulesApiV1ConfigAlertRulesGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAllAlertRulesApiV1ConfigAlertRulesGet = <
  TData = Awaited<ReturnType<typeof getAllAlertRulesApiV1ConfigAlertRulesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetAllAlertRulesApiV1ConfigAlertRulesGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getAllAlertRulesApiV1ConfigAlertRulesGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllAlertRulesApiV1ConfigAlertRulesGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllAlertRulesApiV1ConfigAlertRulesGet>>> = ({ signal }) =>
    getAllAlertRulesApiV1ConfigAlertRulesGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllAlertRulesApiV1ConfigAlertRulesGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Return the application configurations for the client.
 * @summary Application Configurations
 */
export const applicationConfigurationsApiV1ConfigurationsGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/configurations`, method: 'get', signal });
};

export const getApplicationConfigurationsApiV1ConfigurationsGetQueryKey = () => [`/api/v1/configurations`];

export type ApplicationConfigurationsApiV1ConfigurationsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof applicationConfigurationsApiV1ConfigurationsGet>>
>;
export type ApplicationConfigurationsApiV1ConfigurationsGetQueryError = ErrorType<unknown>;

export const useApplicationConfigurationsApiV1ConfigurationsGet = <
  TData = Awaited<ReturnType<typeof applicationConfigurationsApiV1ConfigurationsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof applicationConfigurationsApiV1ConfigurationsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getApplicationConfigurationsApiV1ConfigurationsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof applicationConfigurationsApiV1ConfigurationsGet>>> = ({
    signal
  }) => applicationConfigurationsApiV1ConfigurationsGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof applicationConfigurationsApiV1ConfigurationsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create a new monitor based on a check. This endpoint requires the name, lookback, data_filter and description of the monitor.
 * @summary Create a new monitor.
 */
export const createMonitorApiV1ChecksCheckIdMonitorsPost = (
  checkId: number,
  monitorCreationSchema: MonitorCreationSchema
) => {
  return customInstance<IdResponse>({
    url: `/api/v1/checks/${checkId}/monitors`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: monitorCreationSchema
  });
};

export type CreateMonitorApiV1ChecksCheckIdMonitorsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createMonitorApiV1ChecksCheckIdMonitorsPost>>
>;
export type CreateMonitorApiV1ChecksCheckIdMonitorsPostMutationBody = MonitorCreationSchema;
export type CreateMonitorApiV1ChecksCheckIdMonitorsPostMutationError = ErrorType<HTTPValidationError>;

export const useCreateMonitorApiV1ChecksCheckIdMonitorsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createMonitorApiV1ChecksCheckIdMonitorsPost>>,
    TError,
    { checkId: number; data: MonitorCreationSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createMonitorApiV1ChecksCheckIdMonitorsPost>>,
    { checkId: number; data: MonitorCreationSchema }
  > = props => {
    const { checkId, data } = props ?? {};

    return createMonitorApiV1ChecksCheckIdMonitorsPost(checkId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createMonitorApiV1ChecksCheckIdMonitorsPost>>,
    TError,
    { checkId: number; data: MonitorCreationSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get monitor by id.
 * @summary Get Monitor
 */
export const getMonitorApiV1MonitorsMonitorIdGet = (monitorId: number, signal?: AbortSignal) => {
  return customInstance<MonitorSchema>({ url: `/api/v1/monitors/${monitorId}`, method: 'get', signal });
};

export const getGetMonitorApiV1MonitorsMonitorIdGetQueryKey = (monitorId: number) => [`/api/v1/monitors/${monitorId}`];

export type GetMonitorApiV1MonitorsMonitorIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMonitorApiV1MonitorsMonitorIdGet>>
>;
export type GetMonitorApiV1MonitorsMonitorIdGetQueryError = ErrorType<HTTPValidationError>;

export const useGetMonitorApiV1MonitorsMonitorIdGet = <
  TData = Awaited<ReturnType<typeof getMonitorApiV1MonitorsMonitorIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  monitorId: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getMonitorApiV1MonitorsMonitorIdGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMonitorApiV1MonitorsMonitorIdGetQueryKey(monitorId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMonitorApiV1MonitorsMonitorIdGet>>> = ({ signal }) =>
    getMonitorApiV1MonitorsMonitorIdGet(monitorId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMonitorApiV1MonitorsMonitorIdGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!monitorId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update monitor by id.
 * @summary Update Monitor
 */
export const updateMonitorApiV1MonitorsMonitorIdPut = (monitorId: number, monitorUpdateSchema: MonitorUpdateSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/monitors/${monitorId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: monitorUpdateSchema
  });
};

export type UpdateMonitorApiV1MonitorsMonitorIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateMonitorApiV1MonitorsMonitorIdPut>>
>;
export type UpdateMonitorApiV1MonitorsMonitorIdPutMutationBody = MonitorUpdateSchema;
export type UpdateMonitorApiV1MonitorsMonitorIdPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateMonitorApiV1MonitorsMonitorIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateMonitorApiV1MonitorsMonitorIdPut>>,
    TError,
    { monitorId: number; data: MonitorUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateMonitorApiV1MonitorsMonitorIdPut>>,
    { monitorId: number; data: MonitorUpdateSchema }
  > = props => {
    const { monitorId, data } = props ?? {};

    return updateMonitorApiV1MonitorsMonitorIdPut(monitorId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateMonitorApiV1MonitorsMonitorIdPut>>,
    TError,
    { monitorId: number; data: MonitorUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete monitor by id.
 * @summary Delete Monitor
 */
export const deleteMonitorApiV1MonitorsMonitorIdDelete = (monitorId: number) => {
  return customInstance<unknown>({ url: `/api/v1/monitors/${monitorId}`, method: 'delete' });
};

export type DeleteMonitorApiV1MonitorsMonitorIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMonitorApiV1MonitorsMonitorIdDelete>>
>;

export type DeleteMonitorApiV1MonitorsMonitorIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteMonitorApiV1MonitorsMonitorIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorApiV1MonitorsMonitorIdDelete>>,
    TError,
    { monitorId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMonitorApiV1MonitorsMonitorIdDelete>>,
    { monitorId: number }
  > = props => {
    const { monitorId } = props ?? {};

    return deleteMonitorApiV1MonitorsMonitorIdDelete(monitorId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteMonitorApiV1MonitorsMonitorIdDelete>>,
    TError,
    { monitorId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Run a check on a specified model version and returns a Jupyter notebook with the code to run the check.

Parameters
----------
monitor_id : int
    The id of the monitor to create a notebook to.
notebook_options : MonitorNotebookSchema
    The options for the notebook.
session : AsyncSession, default: AsyncSessionDep
    The database session to use.
settings : Settings, default: SettingsDep

Returns
-------
PlainTextResponse
    A response containing the Jupyter notebook.
 * @summary Get Notebook
 */
export const getNotebookApiV1MonitorsMonitorIdGetNotebookPost = (
  monitorId: number,
  monitorNotebookSchema: MonitorNotebookSchema
) => {
  return customInstance<string>({
    url: `/api/v1/monitors/${monitorId}/get-notebook`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: monitorNotebookSchema
  });
};

export type GetNotebookApiV1MonitorsMonitorIdGetNotebookPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getNotebookApiV1MonitorsMonitorIdGetNotebookPost>>
>;
export type GetNotebookApiV1MonitorsMonitorIdGetNotebookPostMutationBody = MonitorNotebookSchema;
export type GetNotebookApiV1MonitorsMonitorIdGetNotebookPostMutationError = ErrorType<HTTPValidationError>;

export const useGetNotebookApiV1MonitorsMonitorIdGetNotebookPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getNotebookApiV1MonitorsMonitorIdGetNotebookPost>>,
    TError,
    { monitorId: number; data: MonitorNotebookSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getNotebookApiV1MonitorsMonitorIdGetNotebookPost>>,
    { monitorId: number; data: MonitorNotebookSchema }
  > = props => {
    const { monitorId, data } = props ?? {};

    return getNotebookApiV1MonitorsMonitorIdGetNotebookPost(monitorId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getNotebookApiV1MonitorsMonitorIdGetNotebookPost>>,
    TError,
    { monitorId: number; data: MonitorNotebookSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Run a monitor for each time window by lookback.

Parameters
----------
monitor_id : int
    ID of the monitor.
body
session : AsyncSession, optional
    SQLAlchemy session.
cache_funcs
user
resources_provider: ResourcesProvider
    Resources provider.

Returns
-------
CheckResultSchema
    Check run result.
 * @summary Run Monitor Lookback
 */
export const runMonitorLookbackApiV1MonitorsMonitorIdRunPost = (
  monitorId: number,
  monitorRunSchema: MonitorRunSchema
) => {
  return customInstance<CheckResultSchema>({
    url: `/api/v1/monitors/${monitorId}/run`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: monitorRunSchema
  });
};

export type RunMonitorLookbackApiV1MonitorsMonitorIdRunPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof runMonitorLookbackApiV1MonitorsMonitorIdRunPost>>
>;
export type RunMonitorLookbackApiV1MonitorsMonitorIdRunPostMutationBody = MonitorRunSchema;
export type RunMonitorLookbackApiV1MonitorsMonitorIdRunPostMutationError = ErrorType<HTTPValidationError>;

export const useRunMonitorLookbackApiV1MonitorsMonitorIdRunPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runMonitorLookbackApiV1MonitorsMonitorIdRunPost>>,
    TError,
    { monitorId: number; data: MonitorRunSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runMonitorLookbackApiV1MonitorsMonitorIdRunPost>>,
    { monitorId: number; data: MonitorRunSchema }
  > = props => {
    const { monitorId, data } = props ?? {};

    return runMonitorLookbackApiV1MonitorsMonitorIdRunPost(monitorId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof runMonitorLookbackApiV1MonitorsMonitorIdRunPost>>,
    TError,
    { monitorId: number; data: MonitorRunSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get dashboard by if exists, if not then create it. Add top 5 unassigned monitors to the dashboard if empty.
 * @summary Get Or Create Dashboard
 */
export const getOrCreateDashboardApiV1DashboardsGet = (signal?: AbortSignal) => {
  return customInstance<DashboardSchema>({ url: `/api/v1/dashboards`, method: 'get', signal });
};

export const getGetOrCreateDashboardApiV1DashboardsGetQueryKey = () => [`/api/v1/dashboards`];

export type GetOrCreateDashboardApiV1DashboardsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrCreateDashboardApiV1DashboardsGet>>
>;
export type GetOrCreateDashboardApiV1DashboardsGetQueryError = ErrorType<unknown>;

export const useGetOrCreateDashboardApiV1DashboardsGet = <
  TData = Awaited<ReturnType<typeof getOrCreateDashboardApiV1DashboardsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getOrCreateDashboardApiV1DashboardsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrCreateDashboardApiV1DashboardsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrCreateDashboardApiV1DashboardsGet>>> = ({ signal }) =>
    getOrCreateDashboardApiV1DashboardsGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrCreateDashboardApiV1DashboardsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update dashboard by id.
 * @summary Update Dashboard
 */
export const updateDashboardApiV1DashboardsDashboardIdPut = (
  dashboardId: number,
  dashboardUpdateSchema: DashboardUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/dashboards/${dashboardId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: dashboardUpdateSchema
  });
};

export type UpdateDashboardApiV1DashboardsDashboardIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDashboardApiV1DashboardsDashboardIdPut>>
>;
export type UpdateDashboardApiV1DashboardsDashboardIdPutMutationBody = DashboardUpdateSchema;
export type UpdateDashboardApiV1DashboardsDashboardIdPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateDashboardApiV1DashboardsDashboardIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateDashboardApiV1DashboardsDashboardIdPut>>,
    TError,
    { dashboardId: number; data: DashboardUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateDashboardApiV1DashboardsDashboardIdPut>>,
    { dashboardId: number; data: DashboardUpdateSchema }
  > = props => {
    const { dashboardId, data } = props ?? {};

    return updateDashboardApiV1DashboardsDashboardIdPut(dashboardId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateDashboardApiV1DashboardsDashboardIdPut>>,
    TError,
    { dashboardId: number; data: DashboardUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete dashboard by id.
 * @summary Delete Dashboard
 */
export const deleteDashboardApiV1DashboardsDashboardIdDelete = (dashboardId: number) => {
  return customInstance<unknown>({ url: `/api/v1/dashboards/${dashboardId}`, method: 'delete' });
};

export type DeleteDashboardApiV1DashboardsDashboardIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDashboardApiV1DashboardsDashboardIdDelete>>
>;

export type DeleteDashboardApiV1DashboardsDashboardIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteDashboardApiV1DashboardsDashboardIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteDashboardApiV1DashboardsDashboardIdDelete>>,
    TError,
    { dashboardId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteDashboardApiV1DashboardsDashboardIdDelete>>,
    { dashboardId: number }
  > = props => {
    const { dashboardId } = props ?? {};

    return deleteDashboardApiV1DashboardsDashboardIdDelete(dashboardId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteDashboardApiV1DashboardsDashboardIdDelete>>,
    TError,
    { dashboardId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Count alerts.
 * @summary Count Alerts
 */
export const countAlertsApiV1DataIngestionAlertsCountActiveGet = (signal?: AbortSignal) => {
  return customInstance<CountAlertsApiV1DataIngestionAlertsCountActiveGet200>({
    url: `/api/v1/data-ingestion-alerts/count_active`,
    method: 'get',
    signal
  });
};

export const getCountAlertsApiV1DataIngestionAlertsCountActiveGetQueryKey = () => [
  `/api/v1/data-ingestion-alerts/count_active`
];

export type CountAlertsApiV1DataIngestionAlertsCountActiveGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof countAlertsApiV1DataIngestionAlertsCountActiveGet>>
>;
export type CountAlertsApiV1DataIngestionAlertsCountActiveGetQueryError = ErrorType<unknown>;

export const useCountAlertsApiV1DataIngestionAlertsCountActiveGet = <
  TData = Awaited<ReturnType<typeof countAlertsApiV1DataIngestionAlertsCountActiveGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof countAlertsApiV1DataIngestionAlertsCountActiveGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCountAlertsApiV1DataIngestionAlertsCountActiveGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof countAlertsApiV1DataIngestionAlertsCountActiveGet>>> = ({
    signal
  }) => countAlertsApiV1DataIngestionAlertsCountActiveGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof countAlertsApiV1DataIngestionAlertsCountActiveGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Resolve alert by id.
 * @summary Resolve Alert
 */
export const resolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost = (dataIngestionAlertId: number) => {
  return customInstance<unknown>({
    url: `/api/v1/data-ingestion-alerts/${dataIngestionAlertId}/resolve`,
    method: 'post'
  });
};

export type ResolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof resolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost>>
>;

export type ResolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePostMutationError =
  ErrorType<HTTPValidationError>;

export const useResolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost>>,
    TError,
    { dataIngestionAlertId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost>>,
    { dataIngestionAlertId: number }
  > = props => {
    const { dataIngestionAlertId } = props ?? {};

    return resolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost(dataIngestionAlertId);
  };

  return useMutation<
    Awaited<ReturnType<typeof resolveAlertApiV1DataIngestionAlertsDataIngestionAlertIdResolvePost>>,
    TError,
    { dataIngestionAlertId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Reactivate resolved alert.
 * @summary Reactivate Alert
 */
export const reactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost = (
  dataIngestionAlertId: number
) => {
  return customInstance<unknown>({
    url: `/api/v1/data-ingestion-alerts/${dataIngestionAlertId}/reactivate`,
    method: 'post'
  });
};

export type ReactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof reactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost>>
>;

export type ReactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePostMutationError =
  ErrorType<HTTPValidationError>;

export const useReactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof reactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost>>,
    TError,
    { dataIngestionAlertId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof reactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost>>,
    { dataIngestionAlertId: number }
  > = props => {
    const { dataIngestionAlertId } = props ?? {};

    return reactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost(dataIngestionAlertId);
  };

  return useMutation<
    Awaited<ReturnType<typeof reactivateAlertApiV1DataIngestionAlertsDataIngestionAlertIdReactivatePost>>,
    TError,
    { dataIngestionAlertId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get alert by id.
 * @summary Get Alert
 */
export const getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet = (
  dataIngestionAlertId: number,
  signal?: AbortSignal
) => {
  return customInstance<DataIngestionAlertSchema>({
    url: `/api/v1/data-ingestion-alerts/${dataIngestionAlertId}`,
    method: 'get',
    signal
  });
};

export const getGetAlertApiV1DataIngestionAlertsDataIngestionAlertIdGetQueryKey = (dataIngestionAlertId: number) => [
  `/api/v1/data-ingestion-alerts/${dataIngestionAlertId}`
];

export type GetAlertApiV1DataIngestionAlertsDataIngestionAlertIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet>>
>;
export type GetAlertApiV1DataIngestionAlertsDataIngestionAlertIdGetQueryError = ErrorType<HTTPValidationError>;

export const useGetAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet = <
  TData = Awaited<ReturnType<typeof getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  dataIngestionAlertId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAlertApiV1DataIngestionAlertsDataIngestionAlertIdGetQueryKey(dataIngestionAlertId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet>>> = ({
    signal
  }) => getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet(dataIngestionAlertId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAlertApiV1DataIngestionAlertsDataIngestionAlertIdGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!dataIngestionAlertId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Delete alert by id.
 * @summary Delete Alert
 */
export const deleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete = (dataIngestionAlertId: number) => {
  return customInstance<unknown>({ url: `/api/v1/data-ingestion-alerts/${dataIngestionAlertId}`, method: 'delete' });
};

export type DeleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete>>
>;

export type DeleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete>>,
    TError,
    { dataIngestionAlertId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete>>,
    { dataIngestionAlertId: number }
  > = props => {
    const { dataIngestionAlertId } = props ?? {};

    return deleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete(dataIngestionAlertId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteAlertApiV1DataIngestionAlertsDataIngestionAlertIdDelete>>,
    TError,
    { dataIngestionAlertId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Create new alert rule on a given check.
 * @summary Create new alert rule on a given model.
 */
export const createAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost = (
  modelId: number,
  dataIngestionAlertRuleCreationSchema: DataIngestionAlertRuleCreationSchema
) => {
  return customInstance<IdResponse>({
    url: `/api/v1/models/${modelId}/data-ingestion-alert-rules`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: dataIngestionAlertRuleCreationSchema
  });
};

export type CreateAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost>>
>;
export type CreateAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPostMutationBody =
  DataIngestionAlertRuleCreationSchema;
export type CreateAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPostMutationError = ErrorType<HTTPValidationError>;

export const useCreateAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost>>,
    TError,
    { modelId: number; data: DataIngestionAlertRuleCreationSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost>>,
    { modelId: number; data: DataIngestionAlertRuleCreationSchema }
  > = props => {
    const { modelId, data } = props ?? {};

    return createAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost(modelId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createAlertRuleApiV1ModelsModelIdDataIngestionAlertRulesPost>>,
    TError,
    { modelId: number; data: DataIngestionAlertRuleCreationSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get data-ingestion alert rule by id.
 * @summary Get Alert Rule
 */
export const getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet = (
  dataIngestionAlertRuleId: number,
  signal?: AbortSignal
) => {
  return customInstance<DataIngestionAlertRuleSchema>({
    url: `/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}`,
    method: 'get',
    signal
  });
};

export const getGetAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGetQueryKey = (
  dataIngestionAlertRuleId: number
) => [`/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}`];

export type GetAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet>>
>;
export type GetAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGetQueryError =
  ErrorType<HTTPValidationError>;

export const useGetAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet = <
  TData = Awaited<ReturnType<typeof getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  dataIngestionAlertRuleId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGetQueryKey(dataIngestionAlertRuleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet>>
  > = ({ signal }) =>
    getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet(dataIngestionAlertRuleId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!dataIngestionAlertRuleId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update data-ingestion alert rule by id.
 * @summary Update data-ingestion alert rule by id.
 */
export const updateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut = (
  dataIngestionAlertRuleId: number,
  dataIngestionAlertRuleUpdateSchema: DataIngestionAlertRuleUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: dataIngestionAlertRuleUpdateSchema
  });
};

export type UpdateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut>>
>;
export type UpdateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPutMutationBody =
  DataIngestionAlertRuleUpdateSchema;
export type UpdateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPutMutationError =
  ErrorType<HTTPValidationError>;

export const useUpdateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut>>,
    TError,
    { dataIngestionAlertRuleId: number; data: DataIngestionAlertRuleUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut>>,
    { dataIngestionAlertRuleId: number; data: DataIngestionAlertRuleUpdateSchema }
  > = props => {
    const { dataIngestionAlertRuleId, data } = props ?? {};

    return updateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut(dataIngestionAlertRuleId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateAlertApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdPut>>,
    TError,
    { dataIngestionAlertRuleId: number; data: DataIngestionAlertRuleUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete data-ingestion alert rule by id.
 * @summary Delete Alert Rule
 */
export const deleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete = (
  dataIngestionAlertRuleId: number
) => {
  return customInstance<unknown>({
    url: `/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}`,
    method: 'delete'
  });
};

export type DeleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete>>
>;

export type DeleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDeleteMutationError =
  ErrorType<HTTPValidationError>;

export const useDeleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete>>,
    TError,
    { dataIngestionAlertRuleId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete>>,
    { dataIngestionAlertRuleId: number }
  > = props => {
    const { dataIngestionAlertRuleId } = props ?? {};

    return deleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete(dataIngestionAlertRuleId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdDelete>>,
    TError,
    { dataIngestionAlertRuleId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get list of alerts raised by a given data-ingestion alert rule.
 * @summary Get Alerts Of Alert Rule
 */
export const getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet = (
  dataIngestionAlertRuleId: number,
  params?: GetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<DataIngestionAlertSchema[]>({
    url: `/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}/alerts`,
    method: 'get',
    params,
    signal
  });
};

export const getGetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetQueryKey = (
  dataIngestionAlertRuleId: number,
  params?: GetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetParams
) => [`/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}/alerts`, ...(params ? [params] : [])];

export type GetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet>>
>;
export type GetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetQueryError =
  ErrorType<HTTPValidationError>;

export const useGetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet = <
  TData = Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  dataIngestionAlertRuleId: number,
  params?: GetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGetQueryKey(
      dataIngestionAlertRuleId,
      params
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet>>
  > = ({ signal }) =>
    getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet(
      dataIngestionAlertRuleId,
      params,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!dataIngestionAlertRuleId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Resolve all alerts of alert rule.
 * @summary Resolve All Alerts Of Alert Rule
 */
export const resolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost = (
  dataIngestionAlertRuleId: number
) => {
  return customInstance<unknown>({
    url: `/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}/resolve-all`,
    method: 'post'
  });
};

export type ResolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost>
    >
  >;

export type ResolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPostMutationError =
  ErrorType<HTTPValidationError>;

export const useResolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost>
    >,
    TError,
    { dataIngestionAlertRuleId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost>
    >,
    { dataIngestionAlertRuleId: number }
  > = props => {
    const { dataIngestionAlertRuleId } = props ?? {};

    return resolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost(
      dataIngestionAlertRuleId
    );
  };

  return useMutation<
    Awaited<
      ReturnType<typeof resolveAllAlertsOfAlertRuleApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdResolveAllPost>
    >,
    TError,
    { dataIngestionAlertRuleId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Reactivate all resolved alerts
 * @summary Reactivate Resolved Alerts
 */
export const reactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost =
  (dataIngestionAlertRuleId: number) => {
    return customInstance<unknown>({
      url: `/api/v1/data-ingestion-alert-rules/${dataIngestionAlertRuleId}/alerts/reactivate-resolved`,
      method: 'post'
    });
  };

export type ReactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPostMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof reactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost
      >
    >
  >;

export type ReactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPostMutationError =
  ErrorType<HTTPValidationError>;

export const useReactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost =
  <TError = ErrorType<HTTPValidationError>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof reactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost
        >
      >,
      TError,
      { dataIngestionAlertRuleId: number },
      TContext
    >;
  }) => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof reactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost
        >
      >,
      { dataIngestionAlertRuleId: number }
    > = props => {
      const { dataIngestionAlertRuleId } = props ?? {};

      return reactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost(
        dataIngestionAlertRuleId
      );
    };

    return useMutation<
      Awaited<
        ReturnType<
          typeof reactivateResolvedAlertsApiV1DataIngestionAlertRulesDataIngestionAlertRuleIdAlertsReactivateResolvedPost
        >
      >,
      TError,
      { dataIngestionAlertRuleId: number },
      TContext
    >(mutationFn, mutationOptions);
  };

/**
 * This API logs asynchronously a batch of new samples of the inference data of an existing model version, it requires the actual data and validates it matches the version schema.
 * @summary Log inference data per model version.
 */
export const logDataBatchApiV1ModelVersionsModelVersionIdDataPost = (
  modelVersionId: number,
  logDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem: LogDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem[]
) => {
  return customInstance<unknown>({
    url: `/api/v1/model-versions/${modelVersionId}/data`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: logDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem
  });
};

export type LogDataBatchApiV1ModelVersionsModelVersionIdDataPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof logDataBatchApiV1ModelVersionsModelVersionIdDataPost>>
>;
export type LogDataBatchApiV1ModelVersionsModelVersionIdDataPostMutationBody =
  LogDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem[];
export type LogDataBatchApiV1ModelVersionsModelVersionIdDataPostMutationError = ErrorType<HTTPValidationError>;

export const useLogDataBatchApiV1ModelVersionsModelVersionIdDataPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logDataBatchApiV1ModelVersionsModelVersionIdDataPost>>,
    TError,
    { modelVersionId: number; data: LogDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logDataBatchApiV1ModelVersionsModelVersionIdDataPost>>,
    { modelVersionId: number; data: LogDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem[] }
  > = props => {
    const { modelVersionId, data } = props ?? {};

    return logDataBatchApiV1ModelVersionsModelVersionIdDataPost(modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof logDataBatchApiV1ModelVersionsModelVersionIdDataPost>>,
    TError,
    { modelVersionId: number; data: LogDataBatchApiV1ModelVersionsModelVersionIdDataPostBodyItem[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Update data samples.
 * @summary Log Labels
 */
export const logLabelsApiV1ModelModelIdLabelsPut = (
  modelId: number,
  logLabelsApiV1ModelModelIdLabelsPutBodyItem: LogLabelsApiV1ModelModelIdLabelsPutBodyItem[]
) => {
  return customInstance<unknown>({
    url: `/api/v1/model/${modelId}/labels`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: logLabelsApiV1ModelModelIdLabelsPutBodyItem
  });
};

export type LogLabelsApiV1ModelModelIdLabelsPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof logLabelsApiV1ModelModelIdLabelsPut>>
>;
export type LogLabelsApiV1ModelModelIdLabelsPutMutationBody = LogLabelsApiV1ModelModelIdLabelsPutBodyItem[];
export type LogLabelsApiV1ModelModelIdLabelsPutMutationError = ErrorType<HTTPValidationError>;

export const useLogLabelsApiV1ModelModelIdLabelsPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof logLabelsApiV1ModelModelIdLabelsPut>>,
    TError,
    { modelId: number; data: LogLabelsApiV1ModelModelIdLabelsPutBodyItem[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof logLabelsApiV1ModelModelIdLabelsPut>>,
    { modelId: number; data: LogLabelsApiV1ModelModelIdLabelsPutBodyItem[] }
  > = props => {
    const { modelId, data } = props ?? {};

    return logLabelsApiV1ModelModelIdLabelsPut(modelId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof logLabelsApiV1ModelModelIdLabelsPut>>,
    TError,
    { modelId: number; data: LogLabelsApiV1ModelModelIdLabelsPutBodyItem[] },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * This API uploads asynchronously a reference data file for a given model version,it requires the actual data and validates it matches the version schema.
 * @summary Upload reference data for a given model version.
 */
export const saveReferenceApiV1ModelVersionsModelVersionIdReferencePost = (
  modelVersionId: number,
  bodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost: BodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost
) => {
  const formData = new FormData();
  formData.append('batch', bodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost.batch);

  return customInstance<unknown>({
    url: `/api/v1/model-versions/${modelVersionId}/reference`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export type SaveReferenceApiV1ModelVersionsModelVersionIdReferencePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof saveReferenceApiV1ModelVersionsModelVersionIdReferencePost>>
>;
export type SaveReferenceApiV1ModelVersionsModelVersionIdReferencePostMutationBody =
  BodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost;
export type SaveReferenceApiV1ModelVersionsModelVersionIdReferencePostMutationError = ErrorType<HTTPValidationError>;

export const useSaveReferenceApiV1ModelVersionsModelVersionIdReferencePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof saveReferenceApiV1ModelVersionsModelVersionIdReferencePost>>,
    TError,
    { modelVersionId: number; data: BodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof saveReferenceApiV1ModelVersionsModelVersionIdReferencePost>>,
    { modelVersionId: number; data: BodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost }
  > = props => {
    const { modelVersionId, data } = props ?? {};

    return saveReferenceApiV1ModelVersionsModelVersionIdReferencePost(modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof saveReferenceApiV1ModelVersionsModelVersionIdReferencePost>>,
    TError,
    { modelVersionId: number; data: BodySaveReferenceApiV1ModelVersionsModelVersionIdReferencePost },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Create a new model with its name, task type, and description. Returns the ID of the model. If the model already exists, returns the ID of the existing model.
 * @summary Create a new model if does not exist.
 */
export const getCreateModelApiV1ModelsPost = (modelCreationSchema: ModelCreationSchema) => {
  return customInstance<IdResponse>({
    url: `/api/v1/models`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: modelCreationSchema
  });
};

export type GetCreateModelApiV1ModelsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getCreateModelApiV1ModelsPost>>
>;
export type GetCreateModelApiV1ModelsPostMutationBody = ModelCreationSchema;
export type GetCreateModelApiV1ModelsPostMutationError = ErrorType<HTTPValidationError>;

export const useGetCreateModelApiV1ModelsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getCreateModelApiV1ModelsPost>>,
    TError,
    { data: ModelCreationSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getCreateModelApiV1ModelsPost>>,
    { data: ModelCreationSchema }
  > = props => {
    const { data } = props ?? {};

    return getCreateModelApiV1ModelsPost(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getCreateModelApiV1ModelsPost>>,
    TError,
    { data: ModelCreationSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrieve all models data ingestion statistics.
 * @summary Retrieve All Models Data Ingestion
 */
export const retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet = (
  params?: RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetParams,
  signal?: AbortSignal
) => {
  return customInstance<RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet200>({
    url: `/api/v1/models/data-ingestion`,
    method: 'get',
    params,
    signal
  });
};

export const getRetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetQueryKey = (
  params?: RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetParams
) => [`/api/v1/models/data-ingestion`, ...(params ? [params] : [])];

export type RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet>>
>;
export type RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetQueryError = ErrorType<HTTPValidationError>;

export const useRetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet = <
  TData = Awaited<ReturnType<typeof retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: RetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRetrieveAllModelsDataIngestionApiV1ModelsDataIngestionGetQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet>>
  > = ({ signal }) => retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retrieveAllModelsDataIngestionApiV1ModelsDataIngestionGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Retrieve model data ingestion statistics.
 * @summary Retrieve Models Data Ingestion
 */
export const retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet = (
  modelId: string | number,
  params?: RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetParams,
  signal?: AbortSignal
) => {
  return customInstance<RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet200>({
    url: `/api/v1/models/${modelId}/data-ingestion`,
    method: 'get',
    params,
    signal
  });
};

export const getRetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetQueryKey = (
  modelId: string | number,
  params?: RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetParams
) => [`/api/v1/models/${modelId}/data-ingestion`, ...(params ? [params] : [])];

export type RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet>>
>;
export type RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetQueryError = ErrorType<HTTPValidationError>;

export const useRetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet = <
  TData = Awaited<ReturnType<typeof retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: RetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRetrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGetQueryKey(modelId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet>>
  > = ({ signal }) => retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet(modelId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retrieveModelsDataIngestionApiV1ModelsModelIdDataIngestionGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get a model from database based on model id.

Parameters
----------
model_identifier : ModelIdentifier
    Model to return.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
ModelSchema
    Requested model.
 * @summary Get Model
 */
export const getModelApiV1ModelsModelIdGet = (
  modelId: string | number,
  params?: GetModelApiV1ModelsModelIdGetParams,
  signal?: AbortSignal
) => {
  return customInstance<ModelSchema>({ url: `/api/v1/models/${modelId}`, method: 'get', params, signal });
};

export const getGetModelApiV1ModelsModelIdGetQueryKey = (
  modelId: string | number,
  params?: GetModelApiV1ModelsModelIdGetParams
) => [`/api/v1/models/${modelId}`, ...(params ? [params] : [])];

export type GetModelApiV1ModelsModelIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelApiV1ModelsModelIdGet>>
>;
export type GetModelApiV1ModelsModelIdGetQueryError = ErrorType<HTTPValidationError>;

export const useGetModelApiV1ModelsModelIdGet = <
  TData = Awaited<ReturnType<typeof getModelApiV1ModelsModelIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: GetModelApiV1ModelsModelIdGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getModelApiV1ModelsModelIdGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModelApiV1ModelsModelIdGetQueryKey(modelId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelApiV1ModelsModelIdGet>>> = ({ signal }) =>
    getModelApiV1ModelsModelIdGet(modelId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getModelApiV1ModelsModelIdGet>>, TError, TData>(queryKey, queryFn, {
    enabled: !!modelId,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Delete model
 * @summary Delete Model
 */
export const deleteModelApiV1ModelsModelIdDelete = (
  modelId: string | number,
  params?: DeleteModelApiV1ModelsModelIdDeleteParams
) => {
  return customInstance<unknown>({ url: `/api/v1/models/${modelId}`, method: 'delete', params });
};

export type DeleteModelApiV1ModelsModelIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteModelApiV1ModelsModelIdDelete>>
>;

export type DeleteModelApiV1ModelsModelIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteModelApiV1ModelsModelIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteModelApiV1ModelsModelIdDelete>>,
    TError,
    { modelId: string | number; params?: DeleteModelApiV1ModelsModelIdDeleteParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteModelApiV1ModelsModelIdDelete>>,
    { modelId: string | number; params?: DeleteModelApiV1ModelsModelIdDeleteParams }
  > = props => {
    const { modelId, params } = props ?? {};

    return deleteModelApiV1ModelsModelIdDelete(modelId, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteModelApiV1ModelsModelIdDelete>>,
    TError,
    { modelId: string | number; params?: DeleteModelApiV1ModelsModelIdDeleteParams },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Create a new model.

Parameters
----------
model_identifier : ModelIdentifier
    Model to return.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
NameIdResponse
    Created model.
 * @summary Get Versions Per Model
 */
export const getVersionsPerModelApiV1ModelsModelIdVersionsGet = (
  modelId: string | number,
  params?: GetVersionsPerModelApiV1ModelsModelIdVersionsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<NameIdResponse[]>({ url: `/api/v1/models/${modelId}/versions`, method: 'get', params, signal });
};

export const getGetVersionsPerModelApiV1ModelsModelIdVersionsGetQueryKey = (
  modelId: string | number,
  params?: GetVersionsPerModelApiV1ModelsModelIdVersionsGetParams
) => [`/api/v1/models/${modelId}/versions`, ...(params ? [params] : [])];

export type GetVersionsPerModelApiV1ModelsModelIdVersionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVersionsPerModelApiV1ModelsModelIdVersionsGet>>
>;
export type GetVersionsPerModelApiV1ModelsModelIdVersionsGetQueryError = ErrorType<HTTPValidationError>;

export const useGetVersionsPerModelApiV1ModelsModelIdVersionsGet = <
  TData = Awaited<ReturnType<typeof getVersionsPerModelApiV1ModelsModelIdVersionsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: GetVersionsPerModelApiV1ModelsModelIdVersionsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getVersionsPerModelApiV1ModelsModelIdVersionsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetVersionsPerModelApiV1ModelsModelIdVersionsGetQueryKey(modelId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionsPerModelApiV1ModelsModelIdVersionsGet>>> = ({
    signal
  }) => getVersionsPerModelApiV1ModelsModelIdVersionsGet(modelId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getVersionsPerModelApiV1ModelsModelIdVersionsGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!modelId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Retrieve list of available models.
 * @summary Retrieve Available Models
 */
export const retrieveAvailableModelsApiV1AvailableModelsGet = (
  params?: RetrieveAvailableModelsApiV1AvailableModelsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<ModelManagmentSchema[]>({ url: `/api/v1/available-models`, method: 'get', params, signal });
};

export const getRetrieveAvailableModelsApiV1AvailableModelsGetQueryKey = (
  params?: RetrieveAvailableModelsApiV1AvailableModelsGetParams
) => [`/api/v1/available-models`, ...(params ? [params] : [])];

export type RetrieveAvailableModelsApiV1AvailableModelsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveAvailableModelsApiV1AvailableModelsGet>>
>;
export type RetrieveAvailableModelsApiV1AvailableModelsGetQueryError = ErrorType<HTTPValidationError>;

export const useRetrieveAvailableModelsApiV1AvailableModelsGet = <
  TData = Awaited<ReturnType<typeof retrieveAvailableModelsApiV1AvailableModelsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: RetrieveAvailableModelsApiV1AvailableModelsGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveAvailableModelsApiV1AvailableModelsGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveAvailableModelsApiV1AvailableModelsGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveAvailableModelsApiV1AvailableModelsGet>>> = ({
    signal
  }) => retrieveAvailableModelsApiV1AvailableModelsGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveAvailableModelsApiV1AvailableModelsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get statistics of columns for model.

Parameters
----------
model_identifier : ModelIdentifier
    Identifier of a model column of which to return.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
Dict[str, ColumnMetadata]
    Column name and metadata (type and value if available).
 * @summary Get Model Columns
 */
export const getModelColumnsApiV1ModelsModelIdColumnsGet = (
  modelId: string | number,
  params?: GetModelColumnsApiV1ModelsModelIdColumnsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<GetModelColumnsApiV1ModelsModelIdColumnsGet200>({
    url: `/api/v1/models/${modelId}/columns`,
    method: 'get',
    params,
    signal
  });
};

export const getGetModelColumnsApiV1ModelsModelIdColumnsGetQueryKey = (
  modelId: string | number,
  params?: GetModelColumnsApiV1ModelsModelIdColumnsGetParams
) => [`/api/v1/models/${modelId}/columns`, ...(params ? [params] : [])];

export type GetModelColumnsApiV1ModelsModelIdColumnsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getModelColumnsApiV1ModelsModelIdColumnsGet>>
>;
export type GetModelColumnsApiV1ModelsModelIdColumnsGetQueryError = ErrorType<HTTPValidationError>;

export const useGetModelColumnsApiV1ModelsModelIdColumnsGet = <
  TData = Awaited<ReturnType<typeof getModelColumnsApiV1ModelsModelIdColumnsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: GetModelColumnsApiV1ModelsModelIdColumnsGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getModelColumnsApiV1ModelsModelIdColumnsGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetModelColumnsApiV1ModelsModelIdColumnsGetQueryKey(modelId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelColumnsApiV1ModelsModelIdColumnsGet>>> = ({
    signal
  }) => getModelColumnsApiV1ModelsModelIdColumnsGet(modelId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getModelColumnsApiV1ModelsModelIdColumnsGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!modelId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Retrieve list of connected models.
 * @summary Retrieve Connected Models
 */
export const retrieveConnectedModelsApiV1ConnectedModelsGet = (signal?: AbortSignal) => {
  return customInstance<ConnectedModelSchema[]>({ url: `/api/v1/connected-models`, method: 'get', signal });
};

export const getRetrieveConnectedModelsApiV1ConnectedModelsGetQueryKey = () => [`/api/v1/connected-models`];

export type RetrieveConnectedModelsApiV1ConnectedModelsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveConnectedModelsApiV1ConnectedModelsGet>>
>;
export type RetrieveConnectedModelsApiV1ConnectedModelsGetQueryError = ErrorType<unknown>;

export const useRetrieveConnectedModelsApiV1ConnectedModelsGet = <
  TData = Awaited<ReturnType<typeof retrieveConnectedModelsApiV1ConnectedModelsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveConnectedModelsApiV1ConnectedModelsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveConnectedModelsApiV1ConnectedModelsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveConnectedModelsApiV1ConnectedModelsGet>>> = ({
    signal
  }) => retrieveConnectedModelsApiV1ConnectedModelsGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveConnectedModelsApiV1ConnectedModelsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Retrieve list of versions of a connected model.
 * @summary Retrive Connected Model Versions
 */
export const retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet = (
  modelId: number,
  signal?: AbortSignal
) => {
  return customInstance<ConnectedModelVersionSchema[]>({
    url: `/api/v1/connected-models/${modelId}/versions`,
    method: 'get',
    signal
  });
};

export const getRetriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGetQueryKey = (modelId: number) => [
  `/api/v1/connected-models/${modelId}/versions`
];

export type RetriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet>>
>;
export type RetriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGetQueryError =
  ErrorType<HTTPValidationError>;

export const useRetriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet = <
  TData = Awaited<ReturnType<typeof retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRetriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGetQueryKey(modelId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet>>
  > = ({ signal }) => retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet(modelId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retriveConnectedModelVersionsApiV1ConnectedModelsModelIdVersionsGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Retrieve connected model version ingestion errors.
 * @summary Retrieve Connected Model Version Ingestion Errors
 */
export const retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet =
  (
    modelId: number,
    versionId: number,
    params?: RetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetParams,
    signal?: AbortSignal
  ) => {
    return customInstance<IngestionErrorSchema[]>({
      url: `/api/v1/connected-models/${modelId}/versions/${versionId}/ingestion-errors`,
      method: 'get',
      params,
      signal
    });
  };

export const getRetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetQueryKey =
  (
    modelId: number,
    versionId: number,
    params?: RetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetParams
  ) => [`/api/v1/connected-models/${modelId}/versions/${versionId}/ingestion-errors`, ...(params ? [params] : [])];

export type RetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet
      >
    >
  >;
export type RetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetQueryError =
  ErrorType<HTTPValidationError>;

export const useRetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet =
  <
    TData = Awaited<
      ReturnType<
        typeof retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet
      >
    >,
    TError = ErrorType<HTTPValidationError>
  >(
    modelId: number,
    versionId: number,
    params?: RetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet
          >
        >,
        TError,
        TData
      >;
    }
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getRetrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGetQueryKey(
        modelId,
        versionId,
        params
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet
        >
      >
    > = ({ signal }) =>
      retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet(
        modelId,
        versionId,
        params,
        signal
      );

    const query = useQuery<
      Awaited<
        ReturnType<
          typeof retrieveConnectedModelVersionIngestionErrorsApiV1ConnectedModelsModelIdVersionsVersionIdIngestionErrorsGet
        >
      >,
      TError,
      TData
    >(queryKey, queryFn, { enabled: !!(modelId && versionId), ...queryOptions }) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryKey;

    return query;
  };

/**
 * Retrieve connected model ingestion errors.
 * @summary Retrieve Connected Model Ingestion Errors
 */
export const retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet = (
  modelId: number,
  params?: RetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetParams,
  signal?: AbortSignal
) => {
  return customInstance<IngestionErrorSchema[]>({
    url: `/api/v1/connected-models/${modelId}/ingestion-errors`,
    method: 'get',
    params,
    signal
  });
};

export const getRetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetQueryKey = (
  modelId: number,
  params?: RetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetParams
) => [`/api/v1/connected-models/${modelId}/ingestion-errors`, ...(params ? [params] : [])];

export type RetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet>>
>;
export type RetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetQueryError =
  ErrorType<HTTPValidationError>;

export const useRetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet = <
  TData = Awaited<
    ReturnType<typeof retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet>
  >,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: number,
  params?: RetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRetrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGetQueryKey(modelId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet>>
  > = ({ signal }) =>
    retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet(modelId, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retrieveConnectedModelIngestionErrorsApiV1ConnectedModelsModelIdIngestionErrorsGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Set schedule time.
 * @summary Set new scheduling time for all monitors of the model.
 */
export const setScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost = (
  modelId: string | number,
  modelScheduleTimeSchema: ModelScheduleTimeSchema,
  params?: SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostParams
) => {
  return customInstance<unknown>({
    url: `/api/v1/models/${modelId}/monitors-set-schedule-time`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: modelScheduleTimeSchema,
    params
  });
};

export type SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof setScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost>>
>;
export type SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostMutationBody = ModelScheduleTimeSchema;
export type SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostMutationError = ErrorType<HTTPValidationError>;

export const useSetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost>>,
    TError,
    {
      modelId: string | number;
      data: ModelScheduleTimeSchema;
      params?: SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost>>,
    {
      modelId: string | number;
      data: ModelScheduleTimeSchema;
      params?: SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostParams;
    }
  > = props => {
    const { modelId, data, params } = props ?? {};

    return setScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost(modelId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof setScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePost>>,
    TError,
    {
      modelId: string | number;
      data: ModelScheduleTimeSchema;
      params?: SetScheduleTimeApiV1ModelsModelIdMonitorsSetScheduleTimePostParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Retrieve model notes.
 */
export const retrieveModelNotesApiV1ModelsModelIdNotesGet = (
  modelId: string | number,
  params?: RetrieveModelNotesApiV1ModelsModelIdNotesGetParams,
  signal?: AbortSignal
) => {
  return customInstance<ModelNoteSchema[]>({ url: `/api/v1/models/${modelId}/notes`, method: 'get', params, signal });
};

export const getRetrieveModelNotesApiV1ModelsModelIdNotesGetQueryKey = (
  modelId: string | number,
  params?: RetrieveModelNotesApiV1ModelsModelIdNotesGetParams
) => [`/api/v1/models/${modelId}/notes`, ...(params ? [params] : [])];

export type RetrieveModelNotesApiV1ModelsModelIdNotesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveModelNotesApiV1ModelsModelIdNotesGet>>
>;
export type RetrieveModelNotesApiV1ModelsModelIdNotesGetQueryError = ErrorType<HTTPValidationError>;

export const useRetrieveModelNotesApiV1ModelsModelIdNotesGet = <
  TData = Awaited<ReturnType<typeof retrieveModelNotesApiV1ModelsModelIdNotesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelId: string | number,
  params?: RetrieveModelNotesApiV1ModelsModelIdNotesGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveModelNotesApiV1ModelsModelIdNotesGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveModelNotesApiV1ModelsModelIdNotesGetQueryKey(modelId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveModelNotesApiV1ModelsModelIdNotesGet>>> = ({
    signal
  }) => retrieveModelNotesApiV1ModelsModelIdNotesGet(modelId, params, signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveModelNotesApiV1ModelsModelIdNotesGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!modelId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Create model notes.
 */
export const createModelNotesApiV1ModelsModelIdNotesPost = (
  modelId: string | number,
  modelNoteCreationSchema: ModelNoteCreationSchema[],
  params?: CreateModelNotesApiV1ModelsModelIdNotesPostParams
) => {
  return customInstance<ModelNoteSchema[]>({
    url: `/api/v1/models/${modelId}/notes`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: modelNoteCreationSchema,
    params
  });
};

export type CreateModelNotesApiV1ModelsModelIdNotesPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof createModelNotesApiV1ModelsModelIdNotesPost>>
>;
export type CreateModelNotesApiV1ModelsModelIdNotesPostMutationBody = ModelNoteCreationSchema[];
export type CreateModelNotesApiV1ModelsModelIdNotesPostMutationError = ErrorType<HTTPValidationError>;

export const useCreateModelNotesApiV1ModelsModelIdNotesPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createModelNotesApiV1ModelsModelIdNotesPost>>,
    TError,
    {
      modelId: string | number;
      data: ModelNoteCreationSchema[];
      params?: CreateModelNotesApiV1ModelsModelIdNotesPostParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createModelNotesApiV1ModelsModelIdNotesPost>>,
    {
      modelId: string | number;
      data: ModelNoteCreationSchema[];
      params?: CreateModelNotesApiV1ModelsModelIdNotesPostParams;
    }
  > = props => {
    const { modelId, data, params } = props ?? {};

    return createModelNotesApiV1ModelsModelIdNotesPost(modelId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof createModelNotesApiV1ModelsModelIdNotesPost>>,
    TError,
    {
      modelId: string | number;
      data: ModelNoteCreationSchema[];
      params?: CreateModelNotesApiV1ModelsModelIdNotesPostParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Delete model note.
 */
export const deleteModelNoteApiV1ModelsNotesModelNoteIdDelete = (modelNoteId: number) => {
  return customInstance<unknown>({ url: `/api/v1/models-notes/${modelNoteId}`, method: 'delete' });
};

export type DeleteModelNoteApiV1ModelsNotesModelNoteIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteModelNoteApiV1ModelsNotesModelNoteIdDelete>>
>;

export type DeleteModelNoteApiV1ModelsNotesModelNoteIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteModelNoteApiV1ModelsNotesModelNoteIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteModelNoteApiV1ModelsNotesModelNoteIdDelete>>,
    TError,
    { modelNoteId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteModelNoteApiV1ModelsNotesModelNoteIdDelete>>,
    { modelNoteId: number }
  > = props => {
    const { modelNoteId } = props ?? {};

    return deleteModelNoteApiV1ModelsNotesModelNoteIdDelete(modelNoteId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteModelNoteApiV1ModelsNotesModelNoteIdDelete>>,
    TError,
    { modelNoteId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Create a new model version.

Parameters
----------
model_identifier : ModelIdentifier
    ID or name of the model.
info : VersionInfo
    Information about the model version.
session : AsyncSession, optional
    SQLAlchemy session.
 * @summary Get Or Create Version
 */
export const getOrCreateVersionApiV1ModelsModelIdVersionPost = (
  modelId: string | number,
  modelVersionCreationSchema: ModelVersionCreationSchema,
  params?: GetOrCreateVersionApiV1ModelsModelIdVersionPostParams
) => {
  return customInstance<IdResponse>({
    url: `/api/v1/models/${modelId}/version`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: modelVersionCreationSchema,
    params
  });
};

export type GetOrCreateVersionApiV1ModelsModelIdVersionPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getOrCreateVersionApiV1ModelsModelIdVersionPost>>
>;
export type GetOrCreateVersionApiV1ModelsModelIdVersionPostMutationBody = ModelVersionCreationSchema;
export type GetOrCreateVersionApiV1ModelsModelIdVersionPostMutationError = ErrorType<HTTPValidationError>;

export const useGetOrCreateVersionApiV1ModelsModelIdVersionPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getOrCreateVersionApiV1ModelsModelIdVersionPost>>,
    TError,
    {
      modelId: string | number;
      data: ModelVersionCreationSchema;
      params?: GetOrCreateVersionApiV1ModelsModelIdVersionPostParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getOrCreateVersionApiV1ModelsModelIdVersionPost>>,
    {
      modelId: string | number;
      data: ModelVersionCreationSchema;
      params?: GetOrCreateVersionApiV1ModelsModelIdVersionPostParams;
    }
  > = props => {
    const { modelId, data, params } = props ?? {};

    return getOrCreateVersionApiV1ModelsModelIdVersionPost(modelId, data, params);
  };

  return useMutation<
    Awaited<ReturnType<typeof getOrCreateVersionApiV1ModelsModelIdVersionPost>>,
    TError,
    {
      modelId: string | number;
      data: ModelVersionCreationSchema;
      params?: GetOrCreateVersionApiV1ModelsModelIdVersionPostParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Update model version
 * @summary Update Model Version
 */
export const updateModelVersionApiV1ModelVersionsModelVersionIdPut = (
  modelVersionId: number,
  modelVersionUpdateSchema: ModelVersionUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/model-versions/${modelVersionId}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: modelVersionUpdateSchema
  });
};

export type UpdateModelVersionApiV1ModelVersionsModelVersionIdPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateModelVersionApiV1ModelVersionsModelVersionIdPut>>
>;
export type UpdateModelVersionApiV1ModelVersionsModelVersionIdPutMutationBody = ModelVersionUpdateSchema;
export type UpdateModelVersionApiV1ModelVersionsModelVersionIdPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateModelVersionApiV1ModelVersionsModelVersionIdPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateModelVersionApiV1ModelVersionsModelVersionIdPut>>,
    TError,
    { modelVersionId: number; data: ModelVersionUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateModelVersionApiV1ModelVersionsModelVersionIdPut>>,
    { modelVersionId: number; data: ModelVersionUpdateSchema }
  > = props => {
    const { modelVersionId, data } = props ?? {};

    return updateModelVersionApiV1ModelVersionsModelVersionIdPut(modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateModelVersionApiV1ModelVersionsModelVersionIdPut>>,
    TError,
    { modelVersionId: number; data: ModelVersionUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrieve model version.
 * @summary Retrieve Model Version By Id
 */
export const retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet = (
  modelVersionId: number,
  signal?: AbortSignal
) => {
  return customInstance<ModelVersionSchema>({ url: `/api/v1/model-versions/${modelVersionId}`, method: 'get', signal });
};

export const getRetrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGetQueryKey = (modelVersionId: number) => [
  `/api/v1/model-versions/${modelVersionId}`
];

export type RetrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet>>
>;
export type RetrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGetQueryError = ErrorType<HTTPValidationError>;

export const useRetrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet = <
  TData = Awaited<ReturnType<typeof retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelVersionId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getRetrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGetQueryKey(modelVersionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet>>
  > = ({ signal }) => retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet(modelVersionId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retrieveModelVersionByIdApiV1ModelVersionsModelVersionIdGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelVersionId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Delete model version by unique numerical identifier
 * @summary Delete Model Version By Id
 */
export const deleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete = (modelVersionId: number) => {
  return customInstance<unknown>({ url: `/api/v1/model-versions/${modelVersionId}`, method: 'delete' });
};

export type DeleteModelVersionByIdApiV1ModelVersionsModelVersionIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete>>
>;

export type DeleteModelVersionByIdApiV1ModelVersionsModelVersionIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete>>,
    TError,
    { modelVersionId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete>>,
    { modelVersionId: number }
  > = props => {
    const { modelVersionId } = props ?? {};

    return deleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete(modelVersionId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteModelVersionByIdApiV1ModelVersionsModelVersionIdDelete>>,
    TError,
    { modelVersionId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrieve model version.
 * @summary Retrieve Model Version By Name
 */
export const retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet = (
  modelName: string,
  versionName: string,
  signal?: AbortSignal
) => {
  return customInstance<ModelVersionSchema>({
    url: `/api/v1/models/${modelName}/model-versions/${versionName}`,
    method: 'get',
    signal
  });
};

export const getRetrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGetQueryKey = (
  modelName: string,
  versionName: string
) => [`/api/v1/models/${modelName}/model-versions/${versionName}`];

export type RetrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet>>
>;
export type RetrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGetQueryError =
  ErrorType<HTTPValidationError>;

export const useRetrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet = <
  TData = Awaited<ReturnType<typeof retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelName: string,
  versionName: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getRetrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGetQueryKey(modelName, versionName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet>>
  > = ({ signal }) =>
    retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet(modelName, versionName, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retrieveModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!(modelName && versionName), ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Delete model version.
 * @summary Delete Model Version By Name
 */
export const deleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete = (
  modelName: string,
  versionName: string
) => {
  return customInstance<unknown>({
    url: `/api/v1/models/${modelName}/model-versions/${versionName}`,
    method: 'delete'
  });
};

export type DeleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete>>
>;

export type DeleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDeleteMutationError =
  ErrorType<HTTPValidationError>;

export const useDeleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete>>,
    TError,
    { modelName: string; versionName: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete>>,
    { modelName: string; versionName: string }
  > = props => {
    const { modelName, versionName } = props ?? {};

    return deleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete(modelName, versionName);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteModelVersionByNameApiV1ModelsModelNameModelVersionsVersionNameDelete>>,
    TError,
    { modelName: string; versionName: string },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Return json schema of the model version data to use in validation on client-side.

Parameters
----------
model_version_id : int
    Version id to run function on.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
dictionary containing:
    json schema of the monitored data in model version
    json schema of the reference data in model version
    feature columns schema
    non-feature columns schema
 * @summary Get Schema
 */
export const getSchemaApiV1ModelVersionsModelVersionIdSchemaGet = (modelVersionId: number, signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/model-versions/${modelVersionId}/schema`, method: 'get', signal });
};

export const getGetSchemaApiV1ModelVersionsModelVersionIdSchemaGetQueryKey = (modelVersionId: number) => [
  `/api/v1/model-versions/${modelVersionId}/schema`
];

export type GetSchemaApiV1ModelVersionsModelVersionIdSchemaGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSchemaApiV1ModelVersionsModelVersionIdSchemaGet>>
>;
export type GetSchemaApiV1ModelVersionsModelVersionIdSchemaGetQueryError = ErrorType<HTTPValidationError>;

export const useGetSchemaApiV1ModelVersionsModelVersionIdSchemaGet = <
  TData = Awaited<ReturnType<typeof getSchemaApiV1ModelVersionsModelVersionIdSchemaGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelVersionId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSchemaApiV1ModelVersionsModelVersionIdSchemaGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSchemaApiV1ModelVersionsModelVersionIdSchemaGetQueryKey(modelVersionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchemaApiV1ModelVersionsModelVersionIdSchemaGet>>> = ({
    signal
  }) => getSchemaApiV1ModelVersionsModelVersionIdSchemaGet(modelVersionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSchemaApiV1ModelVersionsModelVersionIdSchemaGet>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!modelVersionId, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Run suite (all checks defined) on given model version.

Parameters
----------
model_version_id : int
    Version id to run function on.
monitor_options
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
HTML of the suite result.
 * @summary Run Suite On Model Version
 */
export const runSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost = (
  modelVersionId: number,
  singleCheckRunOptions: SingleCheckRunOptions
) => {
  return customInstance<string>({
    url: `/api/v1/model-versions/${modelVersionId}/suite-run`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: singleCheckRunOptions
  });
};

export type RunSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof runSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost>>
>;
export type RunSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPostMutationBody = SingleCheckRunOptions;
export type RunSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPostMutationError =
  ErrorType<HTTPValidationError>;

export const useRunSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost>>,
    TError,
    { modelVersionId: number; data: SingleCheckRunOptions },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost>>,
    { modelVersionId: number; data: SingleCheckRunOptions }
  > = props => {
    const { modelVersionId, data } = props ?? {};

    return runSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost(modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof runSuiteOnModelVersionApiV1ModelVersionsModelVersionIdSuiteRunPost>>,
    TError,
    { modelVersionId: number; data: SingleCheckRunOptions },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get reference data for a model version.

Parameters
----------
model_version_id : int
    The id of the model version.
monitor_options : TableDataSchema
    The options for the monitor data.
session : AsyncSession
    The database session.

Returns
-------
ORJSONResponse
    The reference data in a json format.
 * @summary Get Model Version Ref Data
 */
export const getModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost = (
  modelVersionId: number,
  tableDataSchema: TableDataSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/model-versions/${modelVersionId}/get-ref-data`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: tableDataSchema
  });
};

export type GetModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost>>
>;
export type GetModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPostMutationBody = TableDataSchema;
export type GetModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPostMutationError =
  ErrorType<HTTPValidationError>;

export const useGetModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost>>,
    TError,
    { modelVersionId: number; data: TableDataSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost>>,
    { modelVersionId: number; data: TableDataSchema }
  > = props => {
    const { modelVersionId, data } = props ?? {};

    return getModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost(modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getModelVersionRefDataApiV1ModelVersionsModelVersionIdGetRefDataPost>>,
    TError,
    { modelVersionId: number; data: TableDataSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get reference data for a model version.

Parameters
----------
model_version_id : int
    The id of the model version.
monitor_options : WindowDataSchema
    The options for the monitor data.
session : AsyncSession
    The database session.

Returns
-------
ORJSONResponse
    The production data in a json format.
 * @summary Get Model Version Prod Data
 */
export const getModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost = (
  modelVersionId: number,
  windowDataSchema: WindowDataSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/model-versions/${modelVersionId}/get-prod-data`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: windowDataSchema
  });
};

export type GetModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof getModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost>>
>;
export type GetModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPostMutationBody = WindowDataSchema;
export type GetModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPostMutationError =
  ErrorType<HTTPValidationError>;

export const useGetModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost>>,
    TError,
    { modelVersionId: number; data: WindowDataSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost>>,
    { modelVersionId: number; data: WindowDataSchema }
  > = props => {
    const { modelVersionId, data } = props ?? {};

    return getModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost(modelVersionId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof getModelVersionProdDataApiV1ModelVersionsModelVersionIdGetProdDataPost>>,
    TError,
    { modelVersionId: number; data: WindowDataSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Return a json containing statistics on samples in the provided time window.

Parameters
----------
model_version_id : int
    Version id to run function on.
body : TimeWindowSchema
    Description of the time window to provide statistics for.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
json schema of the model version
 * @summary Get Time Window Statistics
 */
export const getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet = (
  modelVersionId: number,
  timeWindowSchema: TimeWindowSchema,
  signal?: AbortSignal
) => {
  return customInstance<TimeWindowOutputStatsSchema>({
    url: `/api/v1/model-versions/${modelVersionId}/time-window-statistics`,
    method: 'get',
    headers: { 'Content-Type': 'application/json' },
    signal
  });
};

export const getGetTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGetQueryKey = (
  modelVersionId: number,
  timeWindowSchema: TimeWindowSchema
) => [`/api/v1/model-versions/${modelVersionId}/time-window-statistics`, timeWindowSchema];

export type GetTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet>>
>;
export type GetTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGetQueryError =
  ErrorType<HTTPValidationError>;

export const useGetTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet = <
  TData = Awaited<ReturnType<typeof getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelVersionId: number,
  timeWindowSchema: TimeWindowSchema,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGetQueryKey(
      modelVersionId,
      timeWindowSchema
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet>>
  > = ({ signal }) =>
    getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet(
      modelVersionId,
      timeWindowSchema,
      signal
    );

  const query = useQuery<
    Awaited<ReturnType<typeof getTimeWindowStatisticsApiV1ModelVersionsModelVersionIdTimeWindowStatisticsGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelVersionId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Return json schema of the model version data to use in validation on client-side.

Parameters
----------
model_version_id : int
    Version id to run function on.
session : AsyncSession, optional
    SQLAlchemy session.

Returns
-------
json schema of the model version
 * @summary Get Count Samples
 */
export const getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet = (
  modelVersionId: number,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({
    url: `/api/v1/model-versions/${modelVersionId}/count-samples`,
    method: 'get',
    signal
  });
};

export const getGetCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGetQueryKey = (modelVersionId: number) => [
  `/api/v1/model-versions/${modelVersionId}/count-samples`
];

export type GetCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet>>
>;
export type GetCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGetQueryError = ErrorType<HTTPValidationError>;

export const useGetCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet = <
  TData = Awaited<ReturnType<typeof getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  modelVersionId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGetQueryKey(modelVersionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet>>
  > = ({ signal }) => getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet(modelVersionId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getCountSamplesApiV1ModelVersionsModelVersionIdCountSamplesGet>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!modelVersionId, ...queryOptions }) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get onboarding state.

Parameters
----------
model_name : t.Optional[str]
    Optional to specify a specific model.
session : AsyncSession
    SQLAlchemy session.
 * @summary Get onboarding state
 */
export const getOnboardingStateApiV1OnboardingGet = (
  params?: GetOnboardingStateApiV1OnboardingGetParams,
  signal?: AbortSignal
) => {
  return customInstance<StepSchema>({ url: `/api/v1/onboarding`, method: 'get', params, signal });
};

export const getGetOnboardingStateApiV1OnboardingGetQueryKey = (
  params?: GetOnboardingStateApiV1OnboardingGetParams
) => [`/api/v1/onboarding`, ...(params ? [params] : [])];

export type GetOnboardingStateApiV1OnboardingGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOnboardingStateApiV1OnboardingGet>>
>;
export type GetOnboardingStateApiV1OnboardingGetQueryError = ErrorType<HTTPValidationError>;

export const useGetOnboardingStateApiV1OnboardingGet = <
  TData = Awaited<ReturnType<typeof getOnboardingStateApiV1OnboardingGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params?: GetOnboardingStateApiV1OnboardingGetParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getOnboardingStateApiV1OnboardingGet>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOnboardingStateApiV1OnboardingGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOnboardingStateApiV1OnboardingGet>>> = ({ signal }) =>
    getOnboardingStateApiV1OnboardingGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOnboardingStateApiV1OnboardingGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Redirect to the Auth0 login page.
 * @summary Auth0 Login
 */
export const auth0LoginApiV1AuthLoginAuth0Get = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/login/auth0`, method: 'get', signal });
};

export const getAuth0LoginApiV1AuthLoginAuth0GetQueryKey = () => [`/api/v1/auth/login/auth0`];

export type Auth0LoginApiV1AuthLoginAuth0GetQueryResult = NonNullable<
  Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>
>;
export type Auth0LoginApiV1AuthLoginAuth0GetQueryError = ErrorType<unknown>;

export const useAuth0LoginApiV1AuthLoginAuth0Get = <
  TData = Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuth0LoginApiV1AuthLoginAuth0GetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>> = ({ signal }) =>
    auth0LoginApiV1AuthLoginAuth0Get(signal);

  const query = useQuery<Awaited<ReturnType<typeof auth0LoginApiV1AuthLoginAuth0Get>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get the user details from the Auth0 callback.
 * @summary Auth0 Callback
 */
export const auth0CallbackApiV1AuthLoginAuth0CallbackGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/login/auth0/callback`, method: 'get', signal });
};

export const getAuth0CallbackApiV1AuthLoginAuth0CallbackGetQueryKey = () => [`/api/v1/auth/login/auth0/callback`];

export type Auth0CallbackApiV1AuthLoginAuth0CallbackGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>
>;
export type Auth0CallbackApiV1AuthLoginAuth0CallbackGetQueryError = ErrorType<unknown>;

export const useAuth0CallbackApiV1AuthLoginAuth0CallbackGet = <
  TData = Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAuth0CallbackApiV1AuthLoginAuth0CallbackGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>> = ({
    signal
  }) => auth0CallbackApiV1AuthLoginAuth0CallbackGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof auth0CallbackApiV1AuthLoginAuth0CallbackGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Logout the user.
 * @summary Logout
 */
export const logoutApiV1AuthLogoutGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/auth/logout`, method: 'get', signal });
};

export const getLogoutApiV1AuthLogoutGetQueryKey = () => [`/api/v1/auth/logout`];

export type LogoutApiV1AuthLogoutGetQueryResult = NonNullable<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>>;
export type LogoutApiV1AuthLogoutGetQueryError = ErrorType<unknown>;

export const useLogoutApiV1AuthLogoutGet = <
  TData = Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getLogoutApiV1AuthLogoutGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>> = ({ signal }) =>
    logoutApiV1AuthLogoutGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof logoutApiV1AuthLogoutGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Health check endpoint.

Returns
-------
    Response: A 200 OK response.
 * @summary Health Check
 */
export const healthCheckApiV1HealthCheckGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/health-check`, method: 'get', signal });
};

export const getHealthCheckApiV1HealthCheckGetQueryKey = () => [`/api/v1/health-check`];

export type HealthCheckApiV1HealthCheckGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>
>;
export type HealthCheckApiV1HealthCheckGetQueryError = ErrorType<unknown>;

export const useHealthCheckApiV1HealthCheckGet = <
  TData = Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHealthCheckApiV1HealthCheckGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>> = ({ signal }) =>
    healthCheckApiV1HealthCheckGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof healthCheckApiV1HealthCheckGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Create invite between organization and a user.
 * @summary Create Invite
 */
export const createInviteApiV1OrganizationInvitePut = (invitationCreationSchema: InvitationCreationSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/organization/invite`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: invitationCreationSchema
  });
};

export type CreateInviteApiV1OrganizationInvitePutMutationResult = NonNullable<
  Awaited<ReturnType<typeof createInviteApiV1OrganizationInvitePut>>
>;
export type CreateInviteApiV1OrganizationInvitePutMutationBody = InvitationCreationSchema;
export type CreateInviteApiV1OrganizationInvitePutMutationError = ErrorType<HTTPValidationError>;

export const useCreateInviteApiV1OrganizationInvitePut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createInviteApiV1OrganizationInvitePut>>,
    TError,
    { data: InvitationCreationSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createInviteApiV1OrganizationInvitePut>>,
    { data: InvitationCreationSchema }
  > = props => {
    const { data } = props ?? {};

    return createInviteApiV1OrganizationInvitePut(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createInviteApiV1OrganizationInvitePut>>,
    TError,
    { data: InvitationCreationSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrive an organization.
 * @summary Retrive Organization
 */
export const retriveOrganizationApiV1OrganizationGet = (signal?: AbortSignal) => {
  return customInstance<DeepchecksMonitoringApiV1GlobalApiOrganizationOrganizationSchema>({
    url: `/api/v1/organization`,
    method: 'get',
    signal
  });
};

export const getRetriveOrganizationApiV1OrganizationGetQueryKey = () => [`/api/v1/organization`];

export type RetriveOrganizationApiV1OrganizationGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>
>;
export type RetriveOrganizationApiV1OrganizationGetQueryError = ErrorType<unknown>;

export const useRetriveOrganizationApiV1OrganizationGet = <
  TData = Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetriveOrganizationApiV1OrganizationGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>> = ({ signal }) =>
    retriveOrganizationApiV1OrganizationGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof retriveOrganizationApiV1OrganizationGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Update an organization.
 * @summary Update Organization
 */
export const updateOrganizationApiV1OrganizationPut = (organizationUpdateSchema: OrganizationUpdateSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/organization`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: organizationUpdateSchema
  });
};

export type UpdateOrganizationApiV1OrganizationPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateOrganizationApiV1OrganizationPut>>
>;
export type UpdateOrganizationApiV1OrganizationPutMutationBody = OrganizationUpdateSchema;
export type UpdateOrganizationApiV1OrganizationPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateOrganizationApiV1OrganizationPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateOrganizationApiV1OrganizationPut>>,
    TError,
    { data: OrganizationUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateOrganizationApiV1OrganizationPut>>,
    { data: OrganizationUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return updateOrganizationApiV1OrganizationPut(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateOrganizationApiV1OrganizationPut>>,
    TError,
    { data: OrganizationUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Remove an organization.
 * @summary Remove Organization
 */
export const removeOrganizationApiV1OrganizationDelete = () => {
  return customInstance<unknown>({ url: `/api/v1/organization`, method: 'delete' });
};

export type RemoveOrganizationApiV1OrganizationDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>
>;

export type RemoveOrganizationApiV1OrganizationDeleteMutationError = ErrorType<unknown>;

export const useRemoveOrganizationApiV1OrganizationDelete = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
    TVariables
  > = () => {
    return removeOrganizationApiV1OrganizationDelete();
  };

  return useMutation<
    Awaited<ReturnType<typeof removeOrganizationApiV1OrganizationDelete>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Retrieve organization members
 * @summary Retrieve Organization Members
 */
export const retrieveOrganizationMembersApiV1OrganizationMembersGet = (signal?: AbortSignal) => {
  return customInstance<MemberSchema[]>({ url: `/api/v1/organization/members`, method: 'get', signal });
};

export const getRetrieveOrganizationMembersApiV1OrganizationMembersGetQueryKey = () => [`/api/v1/organization/members`];

export type RetrieveOrganizationMembersApiV1OrganizationMembersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveOrganizationMembersApiV1OrganizationMembersGet>>
>;
export type RetrieveOrganizationMembersApiV1OrganizationMembersGetQueryError = ErrorType<unknown>;

export const useRetrieveOrganizationMembersApiV1OrganizationMembersGet = <
  TData = Awaited<ReturnType<typeof retrieveOrganizationMembersApiV1OrganizationMembersGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof retrieveOrganizationMembersApiV1OrganizationMembersGet>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveOrganizationMembersApiV1OrganizationMembersGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveOrganizationMembersApiV1OrganizationMembersGet>>> = ({
    signal
  }) => retrieveOrganizationMembersApiV1OrganizationMembersGet(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof retrieveOrganizationMembersApiV1OrganizationMembersGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Remove member from an organization
 * @summary Remove Organization Member
 */
export const removeOrganizationMemberApiV1OrganizationMembersMemberIdDelete = (memberId: number) => {
  return customInstance<unknown>({ url: `/api/v1/organization/members/${memberId}`, method: 'delete' });
};

export type RemoveOrganizationMemberApiV1OrganizationMembersMemberIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeOrganizationMemberApiV1OrganizationMembersMemberIdDelete>>
>;

export type RemoveOrganizationMemberApiV1OrganizationMembersMemberIdDeleteMutationError =
  ErrorType<HTTPValidationError>;

export const useRemoveOrganizationMemberApiV1OrganizationMembersMemberIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeOrganizationMemberApiV1OrganizationMembersMemberIdDelete>>,
    TError,
    { memberId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeOrganizationMemberApiV1OrganizationMembersMemberIdDelete>>,
    { memberId: number }
  > = props => {
    const { memberId } = props ?? {};

    return removeOrganizationMemberApiV1OrganizationMembersMemberIdDelete(memberId);
  };

  return useMutation<
    Awaited<ReturnType<typeof removeOrganizationMemberApiV1OrganizationMembersMemberIdDelete>>,
    TError,
    { memberId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Leave the organization
 * @summary Leave Organization
 */
export const leaveOrganizationApiV1OrganizationLeavePost = () => {
  return customInstance<unknown>({ url: `/api/v1/organization/leave`, method: 'post' });
};

export type LeaveOrganizationApiV1OrganizationLeavePostMutationResult = NonNullable<
  Awaited<ReturnType<typeof leaveOrganizationApiV1OrganizationLeavePost>>
>;

export type LeaveOrganizationApiV1OrganizationLeavePostMutationError = ErrorType<unknown>;

export const useLeaveOrganizationApiV1OrganizationLeavePost = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof leaveOrganizationApiV1OrganizationLeavePost>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof leaveOrganizationApiV1OrganizationLeavePost>>,
    TVariables
  > = () => {
    return leaveOrganizationApiV1OrganizationLeavePost();
  };

  return useMutation<
    Awaited<ReturnType<typeof leaveOrganizationApiV1OrganizationLeavePost>>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Get available features
 * @summary Get Available Features
 */
export const getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet = (signal?: AbortSignal) => {
  return customInstance<FeaturesSchema>({ url: `/api/v1/organization/available-features`, method: 'get', signal });
};

export const getGetAvailableFeaturesApiV1OrganizationAvailableFeaturesGetQueryKey = () => [
  `/api/v1/organization/available-features`
];

export type GetAvailableFeaturesApiV1OrganizationAvailableFeaturesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet>>
>;
export type GetAvailableFeaturesApiV1OrganizationAvailableFeaturesGetQueryError = ErrorType<unknown>;

export const useGetAvailableFeaturesApiV1OrganizationAvailableFeaturesGet = <
  TData = Awaited<ReturnType<typeof getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAvailableFeaturesApiV1OrganizationAvailableFeaturesGetQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet>>
  > = ({ signal }) => getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getAvailableFeaturesApiV1OrganizationAvailableFeaturesGet>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Get info needed for the complete details page.
 * @summary Get Complete Details
 */
export const getCompleteDetailsApiV1UsersCompleteDetailsGet = (signal?: AbortSignal) => {
  return customInstance<CompleteDetailsSchema>({ url: `/api/v1/users/complete-details`, method: 'get', signal });
};

export const getGetCompleteDetailsApiV1UsersCompleteDetailsGetQueryKey = () => [`/api/v1/users/complete-details`];

export type GetCompleteDetailsApiV1UsersCompleteDetailsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>
>;
export type GetCompleteDetailsApiV1UsersCompleteDetailsGetQueryError = ErrorType<unknown>;

export const useGetCompleteDetailsApiV1UsersCompleteDetailsGet = <
  TData = Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCompleteDetailsApiV1UsersCompleteDetailsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>> = ({
    signal
  }) => getCompleteDetailsApiV1UsersCompleteDetailsGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof getCompleteDetailsApiV1UsersCompleteDetailsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Complete user details for final login.
 * @summary Update Complete Details
 */
export const updateCompleteDetailsApiV1UsersCompleteDetailsPost = (
  completeDetailsUpdateSchema: CompleteDetailsUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/users/complete-details`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: completeDetailsUpdateSchema
  });
};

export type UpdateCompleteDetailsApiV1UsersCompleteDetailsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCompleteDetailsApiV1UsersCompleteDetailsPost>>
>;
export type UpdateCompleteDetailsApiV1UsersCompleteDetailsPostMutationBody = CompleteDetailsUpdateSchema;
export type UpdateCompleteDetailsApiV1UsersCompleteDetailsPostMutationError = ErrorType<HTTPValidationError>;

export const useUpdateCompleteDetailsApiV1UsersCompleteDetailsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCompleteDetailsApiV1UsersCompleteDetailsPost>>,
    TError,
    { data: CompleteDetailsUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCompleteDetailsApiV1UsersCompleteDetailsPost>>,
    { data: CompleteDetailsUpdateSchema }
  > = props => {
    const { data } = props ?? {};

    return updateCompleteDetailsApiV1UsersCompleteDetailsPost(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateCompleteDetailsApiV1UsersCompleteDetailsPost>>,
    TError,
    { data: CompleteDetailsUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Delete the user.
 * @summary Delete User
 */
export const deleteUserApiV1UsersDelete = () => {
  return customInstance<unknown>({ url: `/api/v1/users`, method: 'delete' });
};

export type DeleteUserApiV1UsersDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserApiV1UsersDelete>>
>;

export type DeleteUserApiV1UsersDeleteMutationError = ErrorType<unknown>;

export const useDeleteUserApiV1UsersDelete = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteUserApiV1UsersDelete>>, TError, TVariables, TContext>;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserApiV1UsersDelete>>, TVariables> = () => {
    return deleteUserApiV1UsersDelete();
  };

  return useMutation<Awaited<ReturnType<typeof deleteUserApiV1UsersDelete>>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions
  );
};

/**
 * Retrieve user details
 * @summary Retrieve User Info
 */
export const retrieveUserInfoApiV1UsersMeGet = (signal?: AbortSignal) => {
  return customInstance<UserSchema>({ url: `/api/v1/users/me`, method: 'get', signal });
};

export const getRetrieveUserInfoApiV1UsersMeGetQueryKey = () => [`/api/v1/users/me`];

export type RetrieveUserInfoApiV1UsersMeGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>
>;
export type RetrieveUserInfoApiV1UsersMeGetQueryError = ErrorType<unknown>;

export const useRetrieveUserInfoApiV1UsersMeGet = <
  TData = Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveUserInfoApiV1UsersMeGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>> = ({ signal }) =>
    retrieveUserInfoApiV1UsersMeGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveUserInfoApiV1UsersMeGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Regenerate user token
 * @summary Regenerate Api Token
 */
export const regenerateApiTokenApiV1UsersRegenerateApiTokenGet = (signal?: AbortSignal) => {
  return customInstance<string>({ url: `/api/v1/users/regenerate-api-token`, method: 'get', signal });
};

export const getRegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryKey = () => [
  `/api/v1/users/regenerate-api-token`
];

export type RegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>
>;
export type RegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryError = ErrorType<unknown>;

export const useRegenerateApiTokenApiV1UsersRegenerateApiTokenGet = <
  TData = Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRegenerateApiTokenApiV1UsersRegenerateApiTokenGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>> = ({
    signal
  }) => regenerateApiTokenApiV1UsersRegenerateApiTokenGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof regenerateApiTokenApiV1UsersRegenerateApiTokenGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Accept End-User License Aggrement
 * @summary Eula-Acceptance
 */
export const eulaAcceptanceApiV1UsersAcceptEulaGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/users/accept-eula`, method: 'get', signal });
};

export const getEulaAcceptanceApiV1UsersAcceptEulaGetQueryKey = () => [`/api/v1/users/accept-eula`];

export type EulaAcceptanceApiV1UsersAcceptEulaGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>
>;
export type EulaAcceptanceApiV1UsersAcceptEulaGetQueryError = ErrorType<unknown>;

export const useEulaAcceptanceApiV1UsersAcceptEulaGet = <
  TData = Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getEulaAcceptanceApiV1UsersAcceptEulaGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>> = ({ signal }) =>
    eulaAcceptanceApiV1UsersAcceptEulaGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof eulaAcceptanceApiV1UsersAcceptEulaGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary Get Data Sources
 */
export const getDataSourcesApiV1DataSourcesGet = (signal?: AbortSignal) => {
  return customInstance<DataSourceSchema[]>({ url: `/api/v1/data-sources`, method: 'get', signal });
};

export const getGetDataSourcesApiV1DataSourcesGetQueryKey = () => [`/api/v1/data-sources`];

export type GetDataSourcesApiV1DataSourcesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDataSourcesApiV1DataSourcesGet>>
>;
export type GetDataSourcesApiV1DataSourcesGetQueryError = ErrorType<unknown>;

export const useGetDataSourcesApiV1DataSourcesGet = <
  TData = Awaited<ReturnType<typeof getDataSourcesApiV1DataSourcesGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getDataSourcesApiV1DataSourcesGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDataSourcesApiV1DataSourcesGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataSourcesApiV1DataSourcesGet>>> = ({ signal }) =>
    getDataSourcesApiV1DataSourcesGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof getDataSourcesApiV1DataSourcesGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * @summary New Data Source
 */
export const newDataSourceApiV1DataSourcesPut = (dataSourceCreationSchema: DataSourceCreationSchema) => {
  return customInstance<unknown>({
    url: `/api/v1/data-sources`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: dataSourceCreationSchema
  });
};

export type NewDataSourceApiV1DataSourcesPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof newDataSourceApiV1DataSourcesPut>>
>;
export type NewDataSourceApiV1DataSourcesPutMutationBody = DataSourceCreationSchema;
export type NewDataSourceApiV1DataSourcesPutMutationError = ErrorType<HTTPValidationError>;

export const useNewDataSourceApiV1DataSourcesPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newDataSourceApiV1DataSourcesPut>>,
    TError,
    { data: DataSourceCreationSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newDataSourceApiV1DataSourcesPut>>,
    { data: DataSourceCreationSchema }
  > = props => {
    const { data } = props ?? {};

    return newDataSourceApiV1DataSourcesPut(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof newDataSourceApiV1DataSourcesPut>>,
    TError,
    { data: DataSourceCreationSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * @summary Delete Data Source
 */
export const deleteDataSourceApiV1DataSourcesDataSourceIdDelete = (dataSourceId: number) => {
  return customInstance<unknown>({ url: `/api/v1/data-sources/${dataSourceId}`, method: 'delete' });
};

export type DeleteDataSourceApiV1DataSourcesDataSourceIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDataSourceApiV1DataSourcesDataSourceIdDelete>>
>;

export type DeleteDataSourceApiV1DataSourcesDataSourceIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useDeleteDataSourceApiV1DataSourcesDataSourceIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteDataSourceApiV1DataSourcesDataSourceIdDelete>>,
    TError,
    { dataSourceId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteDataSourceApiV1DataSourcesDataSourceIdDelete>>,
    { dataSourceId: number }
  > = props => {
    const { dataSourceId } = props ?? {};

    return deleteDataSourceApiV1DataSourcesDataSourceIdDelete(dataSourceId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteDataSourceApiV1DataSourcesDataSourceIdDelete>>,
    TError,
    { dataSourceId: number },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Update user roles.
 * @summary Update User Role
 */
export const updateUserRoleApiV1UsersUserIdRolesPut = (userId: number, roleUpdateSchema: RoleUpdateSchema) => {
  return customInstance<UserSchema>({
    url: `/api/v1/users/${userId}/roles`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: roleUpdateSchema
  });
};

export type UpdateUserRoleApiV1UsersUserIdRolesPutMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserRoleApiV1UsersUserIdRolesPut>>
>;
export type UpdateUserRoleApiV1UsersUserIdRolesPutMutationBody = RoleUpdateSchema;
export type UpdateUserRoleApiV1UsersUserIdRolesPutMutationError = ErrorType<HTTPValidationError>;

export const useUpdateUserRoleApiV1UsersUserIdRolesPut = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserRoleApiV1UsersUserIdRolesPut>>,
    TError,
    { userId: number; data: RoleUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserRoleApiV1UsersUserIdRolesPut>>,
    { userId: number; data: RoleUpdateSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return updateUserRoleApiV1UsersUserIdRolesPut(userId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUserRoleApiV1UsersUserIdRolesPut>>,
    TError,
    { userId: number; data: RoleUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Assign models to user.
 * @summary Assign Models To User
 */
export const assignModelsToUserApiV1UsersUserIdModelsPost = (
  userId: number,
  memberUpdateSchema: MemberUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/users/${userId}/models`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: memberUpdateSchema
  });
};

export type AssignModelsToUserApiV1UsersUserIdModelsPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof assignModelsToUserApiV1UsersUserIdModelsPost>>
>;
export type AssignModelsToUserApiV1UsersUserIdModelsPostMutationBody = MemberUpdateSchema;
export type AssignModelsToUserApiV1UsersUserIdModelsPostMutationError = ErrorType<HTTPValidationError>;

export const useAssignModelsToUserApiV1UsersUserIdModelsPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assignModelsToUserApiV1UsersUserIdModelsPost>>,
    TError,
    { userId: number; data: MemberUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assignModelsToUserApiV1UsersUserIdModelsPost>>,
    { userId: number; data: MemberUpdateSchema }
  > = props => {
    const { userId, data } = props ?? {};

    return assignModelsToUserApiV1UsersUserIdModelsPost(userId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof assignModelsToUserApiV1UsersUserIdModelsPost>>,
    TError,
    { userId: number; data: MemberUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Assign users to model.
 * @summary Assign Users To Model
 */
export const assignUsersToModelApiV1ModelsModelIdMembersPost = (
  modelId: number,
  batchModelMemberUpdateSchema: BatchModelMemberUpdateSchema
) => {
  return customInstance<unknown>({
    url: `/api/v1/models/${modelId}/members`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: batchModelMemberUpdateSchema
  });
};

export type AssignUsersToModelApiV1ModelsModelIdMembersPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof assignUsersToModelApiV1ModelsModelIdMembersPost>>
>;
export type AssignUsersToModelApiV1ModelsModelIdMembersPostMutationBody = BatchModelMemberUpdateSchema;
export type AssignUsersToModelApiV1ModelsModelIdMembersPostMutationError = ErrorType<HTTPValidationError>;

export const useAssignUsersToModelApiV1ModelsModelIdMembersPost = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof assignUsersToModelApiV1ModelsModelIdMembersPost>>,
    TError,
    { modelId: number; data: BatchModelMemberUpdateSchema },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof assignUsersToModelApiV1ModelsModelIdMembersPost>>,
    { modelId: number; data: BatchModelMemberUpdateSchema }
  > = props => {
    const { modelId, data } = props ?? {};

    return assignUsersToModelApiV1ModelsModelIdMembersPost(modelId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof assignUsersToModelApiV1ModelsModelIdMembersPost>>,
    TError,
    { modelId: number; data: BatchModelMemberUpdateSchema },
    TContext
  >(mutationFn, mutationOptions);
};

/**
 * Redirect user to the slack authorization page.

codeflow:
1. Authenticate user
2. Verify whether user has permissions to do operation
3. Issue 'installation state' to prevent forgery attack
4. Generate redirection URL
5. Set 'installation state' cookie
6. Redirect user to slack authorization page.

Slack authorization URL description:
https://slack.com/oauth/v2/authorize?state=&client_id=&scope=&user_scope=
state - installation state, slack will include it in request with exchange code
client_id - application client id
scope - list of bot permissions
user_scope -
 * @summary Slack-Authorization-Redirect
 */
export const slackAuthorizationRedirectApiV1SlackAuthorizeGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/slack.authorize`, method: 'get', signal });
};

export const getSlackAuthorizationRedirectApiV1SlackAuthorizeGetQueryKey = () => [`/api/v1/slack.authorize`];

export type SlackAuthorizationRedirectApiV1SlackAuthorizeGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof slackAuthorizationRedirectApiV1SlackAuthorizeGet>>
>;
export type SlackAuthorizationRedirectApiV1SlackAuthorizeGetQueryError = ErrorType<unknown>;

export const useSlackAuthorizationRedirectApiV1SlackAuthorizeGet = <
  TData = Awaited<ReturnType<typeof slackAuthorizationRedirectApiV1SlackAuthorizeGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof slackAuthorizationRedirectApiV1SlackAuthorizeGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSlackAuthorizationRedirectApiV1SlackAuthorizeGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof slackAuthorizationRedirectApiV1SlackAuthorizeGet>>> = ({
    signal
  }) => slackAuthorizationRedirectApiV1SlackAuthorizeGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof slackAuthorizationRedirectApiV1SlackAuthorizeGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Finish slack installation.

When a user confirms application (bot) installation,
slack redirects him back to the 'redirect_uri' URL
provided within the authorization request.

Slack will include the next query parameters with the redirection URL:
code - access token exchange code
error - error message if something went wrong
state - installation state token that was passed with an authorization request.
 * @summary Slack-Installation-Callback
 */
export const slackInstallationCallbackApiV1SlackInstallGet = (
  params: SlackInstallationCallbackApiV1SlackInstallGetParams,
  signal?: AbortSignal
) => {
  return customInstance<unknown>({ url: `/api/v1/slack.install`, method: 'get', params, signal });
};

export const getSlackInstallationCallbackApiV1SlackInstallGetQueryKey = (
  params: SlackInstallationCallbackApiV1SlackInstallGetParams
) => [`/api/v1/slack.install`, ...(params ? [params] : [])];

export type SlackInstallationCallbackApiV1SlackInstallGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof slackInstallationCallbackApiV1SlackInstallGet>>
>;
export type SlackInstallationCallbackApiV1SlackInstallGetQueryError = ErrorType<HTTPValidationError>;

export const useSlackInstallationCallbackApiV1SlackInstallGet = <
  TData = Awaited<ReturnType<typeof slackInstallationCallbackApiV1SlackInstallGet>>,
  TError = ErrorType<HTTPValidationError>
>(
  params: SlackInstallationCallbackApiV1SlackInstallGetParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof slackInstallationCallbackApiV1SlackInstallGet>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSlackInstallationCallbackApiV1SlackInstallGetQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof slackInstallationCallbackApiV1SlackInstallGet>>> = ({
    signal
  }) => slackInstallationCallbackApiV1SlackInstallGet(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof slackInstallationCallbackApiV1SlackInstallGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Return list of slack installations.
 * @summary Retrieve Instalations
 */
export const retrieveInstalationsApiV1SlackAppsGet = (signal?: AbortSignal) => {
  return customInstance<unknown>({ url: `/api/v1/slack/apps`, method: 'get', signal });
};

export const getRetrieveInstalationsApiV1SlackAppsGetQueryKey = () => [`/api/v1/slack/apps`];

export type RetrieveInstalationsApiV1SlackAppsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof retrieveInstalationsApiV1SlackAppsGet>>
>;
export type RetrieveInstalationsApiV1SlackAppsGetQueryError = ErrorType<unknown>;

export const useRetrieveInstalationsApiV1SlackAppsGet = <
  TData = Awaited<ReturnType<typeof retrieveInstalationsApiV1SlackAppsGet>>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof retrieveInstalationsApiV1SlackAppsGet>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveInstalationsApiV1SlackAppsGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveInstalationsApiV1SlackAppsGet>>> = ({ signal }) =>
    retrieveInstalationsApiV1SlackAppsGet(signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveInstalationsApiV1SlackAppsGet>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

/**
 * Remove slack installation.
 * @summary Remove Installation
 */
export const removeInstallationApiV1SlackAppsAppIdDelete = (appId: number) => {
  return customInstance<unknown>({ url: `/api/v1/slack/apps/${appId}`, method: 'delete' });
};

export type RemoveInstallationApiV1SlackAppsAppIdDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof removeInstallationApiV1SlackAppsAppIdDelete>>
>;

export type RemoveInstallationApiV1SlackAppsAppIdDeleteMutationError = ErrorType<HTTPValidationError>;

export const useRemoveInstallationApiV1SlackAppsAppIdDelete = <
  TError = ErrorType<HTTPValidationError>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof removeInstallationApiV1SlackAppsAppIdDelete>>,
    TError,
    { appId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof removeInstallationApiV1SlackAppsAppIdDelete>>,
    { appId: number }
  > = props => {
    const { appId } = props ?? {};

    return removeInstallationApiV1SlackAppsAppIdDelete(appId);
  };

  return useMutation<
    Awaited<ReturnType<typeof removeInstallationApiV1SlackAppsAppIdDelete>>,
    TError,
    { appId: number },
    TContext
  >(mutationFn, mutationOptions);
};
